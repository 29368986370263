<template>
  <div class="lb-w-chat-input">
    <div class="input-wr">
      <div
        dir="ltr"
        ref="messageInputRef"
        contenteditable="true"
        class="input-message"
        :aria-disabled="messageSending"
        :placeholder="STRINGS.ENTER_YOUR_MESSAGE_HERE"
        @blur="endTyping"
        @input="changeHandler"
        @click="updateSelection"
        @keypress="updateSelection"
        @keydown="sendMessageOnEnter"
      ></div>
      <div class="lb-w-chat-btn">
        <button
          class="btn btn-only-icon"
          @click="sendTextMessage"
        >
          <span class="icon"><svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 21l21-9L2 3v7l15 2-15 2v7z" class="hv-svg-fill"/></svg></span>
        </button>
        <div class="drop-emoji">
          <picker
            class="drop-emoji-wrap"
            set="twitter"
            emoji="point_up"
            :data="emojiIndex"
            v-if="showEmojiPicker"
            @select="emojiClicked"
          />
          <button
            class="btn btn-only-icon"
            @click="toggleEmojiPicker"
          >
            <span class="icon"><svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 2a10 10 0 100 20 10 10 0 000-20zM8.5 8a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM12 18c-2.3 0-4.2-1.7-5-4h10c-.8 2.3-2.7 4-5 4zm3.5-7a1.5 1.5 0 110-3 1.5 1.5 0 010 3z" class="hv-svg-fill"/></svg></span>
          </button>
        </div>
        <label class="btn btn-only-icon">
          <span class="icon"><svg height="24px" viewBox="0 -21 512 511" width="24px" xmlns="http://www.w3.org/2000/svg"><path d="m378 470-13-2-329-88c-26-7-41-33-34-59l36-139c2-8 11-14 19-11 9 2 14 11 12 19L33 329c-3 9 2 18 11 20l330 88c8 3 17-2 19-11l11-45a16 16 0 0 1 31 7l-11 46c-6 22-25 36-46 36zm0 0" class="hv-svg-fill"/><path d="M464 342H133c-26 0-48-22-48-48V48c0-26 22-48 48-48h331c26 0 48 22 48 48v246c0 26-22 48-48 48zM133 32c-9 0-16 8-16 16v246c0 9 7 16 16 16h331c9 0 16-7 16-16V48c0-8-7-16-16-16zm0 0" class="hv-svg-fill"/><path d="M192 150a43 43 0 1 1 0-86 43 43 0 0 1 0 86zm0-54a11 11 0 1 0 0 22 11 11 0 0 0 0-22zM102 320c-4 0-8-1-12-5-6-6-6-16 0-22l97-97c14-14 38-14 53 0l26 26 79-95c7-8 17-13 28-13 12-1 22 4 29 13l106 124a16 16 0 0 1-24 20L378 147l-5-1-4 2-90 108a16 16 0 0 1-24 1l-38-38c-3-3-5-3-8 0l-96 96c-3 4-7 5-11 5zm0 0" class="hv-svg-fill"/></svg></span>
          <input
            hidden
            style="display: none"
            type="file"
            accept="image/*"
            id="imageUploaderRef"
            @change="onImageChange"
          />
        </label>
        <label class="btn btn-only-icon">
          <span class="icon"><svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.5 6v11.5a4 4 0 11-8 0V5a2.5 2.5 0 015 0v10.5c0 .6-.4 1-1 1a1 1 0 01-1-1V6H10v9.5a2.5 2.5 0 005 0V5a4 4 0 10-8 0v12.5a5.5 5.5 0 1011 0V6h-1.5z" class="hv-svg-fill"/></svg></span>
          <input
            hidden
            style="display: none"
            type="file"
            id="fileUploaderRef"
            @change="onFileChange"
          />
        </label>
      </div>
      <div
        class="drop-emoji-overlay"
        v-if="showEmojiPicker"
        @click="toggleEmojiPicker"
      ></div>
    </div>
  </div>
</template>
<script>
import { CometChat } from '@cometchat-pro/chat'

import { Picker, EmojiIndex } from 'emoji-mart-vue-fast/src'
import data from 'emoji-mart-vue-fast/data/all.json'
import 'emoji-mart-vue-fast/css/emoji-mart.css'

import { COMETCHAT_CONSTANTS, DEFAULT_OBJECT_PROP, DEFAULT_STRING_PROP } from '../../../resources/constants'

import * as enums from '../../../util/enums'
import { propertyCheck, cometChatCommon } from '../../../mixins/'
import { getExtensionsDataFromMessage } from '../../../util/common'

import { outgoingMessageAlert } from '../../../resources/audio/'

let sel, range

/**
 * Used for composing all messages.
 */
export default {
  name: 'ChatCometComposer',
  mixins: [propertyCheck, cometChatCommon],
  components: {
    Picker
  },
  props: {
    /**
     * The selected chat item object.
     */
    item: { ...DEFAULT_OBJECT_PROP },
    /**
     * Type of chat item.
     */
    type: { ...DEFAULT_STRING_PROP },
    /**
     * Theme of the UI.
     */ theme: { ...DEFAULT_OBJECT_PROP },
    /**
     * The smart reply extension data.
     */
    smartReply: { ...DEFAULT_OBJECT_PROP },
    /**
     * The message selected to edit.
     */
    messageToEdit: { ...DEFAULT_OBJECT_PROP },
    /**
     * Id of parent for the given message.
     */
    parentMessageId: { ...DEFAULT_STRING_PROP },
    /**
     * Reaction name.
     */
    reaction: { ...DEFAULT_STRING_PROP, default: 'heart' }
  },
  data () {
    return {
      isTyping: null,
      messageInput: '',
      messageType: '',
      replyPreview: null,
      messageSending: false,
      showFilePicker: false,
      showEmojiPicker: false,
      messageToBeEdited: null,
      showStickerPicker: false,
      emojiIndex: new EmojiIndex(data)
    }
  },
  watch: {
    /**
     * Updates the composer input value on selecting a message to edit.
     */
    messageToEdit: {
      handler (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.messageToBeEdited = newValue

          if (newValue) {
            this.updateMessageTextDOM(newValue.text)
          } else {
            this.updateMessageTextDOM('')
          }
        }
      },
      deep: true
    },
    /**
     * Updates local state on change.
     */
    smartReply: {
      handler (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.replyPreview = newValue
        }
      },
      deep: true
    },
    /**
     * Hides/Locks everything in composer, if blocked.
     */
    isBlockedByMe (newValue) {
      if (newValue) {
        this.showFilePicker = false
        this.showEmojiPicker = false
        this.showStickerPicker = false
      }
    }
  },
  computed: {
    /**
     * Local string constants.
     */
    STRINGS () {
      return COMETCHAT_CONSTANTS
    },
    /**
     * Returns if user of selected item is blocked by current user.
     */
    isBlockedByMe () {
      return this.item.blockedByMe
    },
    /**
     * Computed source of reaction icon.
     */
    reactionIconSrc () {
      return this.icons[enums.LIVE_REACTIONS[this.reaction]]
    },
    smartReplyOptions () {
      let options = null

      if (this.replyPreview) {
        const smartReplyObject = getExtensionsDataFromMessage(
          this.replyPreview,
          'smart-reply'
        )

        if (smartReplyObject) {
          options = [
            smartReplyObject['reply_positive'],
            smartReplyObject['reply_neutral'],
            smartReplyObject['reply_negative']
          ]
        }
      }
      return options
    }
  },
  methods: {
    /**
     * Handles emitted action events
     */
    actionHandler ({ action, message, sticker }) {
      switch (action) {
        case 'pollCreated':
          this.toggleCreatePoll()
          this.toggleFilePicker()
          if (this.type === 'user') {
            this.emitAction('pollCreated', { messages: [message] })
          }
          break
        case 'sendSticker':
          this.sendSticker(sticker)
          break
        case 'closeSticker':
          this.toggleStickerPicker()
          break
        default:
          break
      }
    },
    /**
     * Toggles file picker
     */
    toggleFilePicker () {
      if (this.isBlockedByMe) return

      this.showFilePicker = !this.showFilePicker
    },
    /**
     * Toggles sticker picker
     */
    toggleStickerPicker () {
      if (this.isBlockedByMe) return

      this.showStickerPicker = !this.showStickerPicker
    },
    /**
     * Emits "clearEditPreview" action
     */
    closeEditPreview () {
      this.emitAction('clearEditPreview')
    },
    /**
     * Send transient message
     */
    sendMessage () {
      const metadata = { type: enums.LIVE_REACTION_KEY, reaction: this.reaction }
      const receiverType = this.type === CometChat.ACTION_TYPE.TYPE_USER ? CometChat.ACTION_TYPE.TYPE_USER : CometChat.ACTION_TYPE.TYPE_GROUP
      const receiverId = (this.type === CometChat.ACTION_TYPE.TYPE_USER) ? this.item.uid : this.item.guid

      const transientMessage = new CometChat.TransientMessage(receiverId, receiverType, metadata)
      CometChat.sendTransientMessage(transientMessage)
    },
    /**
     * Sends start typing indicator signal to server
     */
    startTyping (metadata, timer = 5000) {
      if (this.isTyping) {
        return false
      }

      try {
        const { receiverId, receiverType } = this.getReceiverDetails()

        const typingNotification = new CometChat.TypingIndicator(
          receiverId,
          receiverType,
          metadata || undefined
        )
        CometChat.startTyping(typingNotification)

        this.isTyping = setTimeout(() => {
          clearTimeout(this.isTyping)
          this.isTyping = null
        }, timer)
      } catch (e) {
        this.logError(e)
      }
    },
    /**
     * Sends end typing indicator signal to server
     */
    endTyping () {
      try {
        const { receiverId, receiverType } = this.getReceiverDetails()

        const typingNotification = new CometChat.TypingIndicator(
          receiverId,
          receiverType
        )
        CometChat.endTyping(typingNotification)
      } catch (e) {
        this.logError(e)
      }
    },
    /**
     * Handles change in input
     */
    changeHandler (event) {
      try {
        this.startTyping()

        const element = event.target
        const input = element.textContent.trim()

        if (!input.length) {
          event.target.textContent = input
        }

        this.setMessageInput(element)
      } catch (e) {
        this.logError(e)
      }
    },
    /**
     * Handles emoji click
     */
    emojiClicked (emoji) {
      this.$nextTick(() => {
        try {
          if (this.$refs && this.$refs.messageInputRef) {
            const element = this.$refs.messageInputRef
            element.focus()

            this.pasteHtmlAtCaret(emoji.native)

            this.setMessageInput(element)
          }
        } catch (e) {
          this.logError(e)
        }
      })
    },
    /**
     * Pastes given html at caret position
     */
    pasteHtmlAtCaret (html) {
      try {
        if (sel && range) {
          range.deleteContents()

          const el = document.createElement('div')
          el.innerHTML = html
          const frag = document.createDocumentFragment()
          let node
          let lastNode
          while ((node = el.firstChild)) {
            lastNode = frag.appendChild(node)
          }
          range.insertNode(frag)

          if (lastNode) {
            range = range.cloneRange()
            range.setStartAfter(lastNode)
            range.collapse(true)
            sel.removeAllRanges()
            sel.addRange(range)
          }
        } else if (document.selection && document.selection.type !== 'Control') {
          document.selection.createRange().pasteHTML(html)
        }
      } catch (e) {
        this.logError(e)
      }
    },
    /**
     * Updates caret selection object
     */
    updateSelection () {
      this.$nextTick(() => {
        try {
          if (window.getSelection) {
            sel = window.getSelection()
            if (sel.getRangeAt && sel.rangeCount) {
              range = sel.getRangeAt(0)
            }
          }
        } catch (error) {
          this.logError('Error updating selection', error)
        }
      })
    },
    /**
     * Sets message input
     */
    setMessageInput (element) {
      this.messageInput = element.innerText
      this.messageType = 'text'
    },
    /**
     * Calls send message on enter
     */
    sendMessageOnEnter (event) {
      try {
        if (event.keyCode === 13 && !event.shiftKey) {
          event.preventDefault()

          this.updateMessageTextDOM('')
          this.sendTextMessage()
          return true
        }
      } catch (e) {
        this.logError(e)
      }
    },
    /**
     * Helper function to upload media files
     */
    uploadMedia (event, type) {
      try {
        const uploadedFile = event.target.files['0']

        if (!uploadedFile) {
          return false
        }

        const reader = new FileReader()
        reader.addEventListener(
          'load',
          () => {
            const newFile = new File(
              [reader.result],
              uploadedFile.name,
              uploadedFile
            )
            this.sendMediaMessage(newFile, type)
          },
          false
        )

        reader.readAsArrayBuffer(uploadedFile)
      } catch (e) {
        this.logError(e)
      }
    },
    /**
     * Uploads on image chnage
     */
    onImageChange () {
      if (this.isBlockedByMe) return

      this.uploadMedia(event, 'image')
    },
    /**
     * Uploads on file chnage
     */
    onFileChange () {
      if (this.isBlockedByMe) return

      this.uploadMedia(event, 'file')
    },
    /**
     * Sends sticker
     */
    async sendSticker (sticker) {
      if (this.isBlockedByMe) return

      this.messageSending = true

      try {
        const { receiverId, receiverType } = this.getReceiverDetails()

        const customData = {
          sticker_url: sticker.stickerUrl,
          sticker_name: sticker.stickerName
        }
        const customType = enums.CUSTOM_TYPE_STICKER

        const customMessage = new CometChat.CustomMessage(
          receiverId,
          receiverType,
          customType,
          customData
        )

        if (this.parentMessageId) {
          customMessage.setParentMessageId(this.parentMessageId)
        }

        const response = await CometChat.sendCustomMessage(customMessage)

        this.playAudio()

        this.emitAction('messageComposed', { messages: [response] })
      } catch (e) {
        this.logError(e)
      } finally {
        this.messageSending = false
      }
    },
    /**
     * Sends any media message
     */
    async sendMediaMessage (messageInput, messageType) {
      if (this.isBlockedByMe) return

      try {
        this.toggleFilePicker()

        if (this.messageSending) {
          return false
        }

        this.messageSending = true;

        let receiverId
        const receiverType = this.type
        if (this.type === 'user') {
          receiverId = this.item.uid
        } else if (this.type === 'group') {
          receiverId = this.item.guid
        }

        const message = new CometChat.MediaMessage(
          receiverId,
          messageInput,
          messageType,
          receiverType
        )

        if (this.parentMessageId) {
          message.setParentMessageId(this.parentMessageId)
        }

        this.endTyping()

        const response = await CometChat.sendMessage(message)

        this.playAudio()
        this.emitAction('messageComposed', { messages: [response] })
      } catch (e) {
        this.logError(e)
      } finally {
        this.messageSending = false
      }
    },
    /**
     *
     */
    async sendReplyMessage (messageInput) {
      if (this.isBlockedByMe) return

      this.replyPreview = null

      try {
        const { receiverId, receiverType } = this.getReceiverDetails()

        if (this.messageSending) {
          return false
        }

        this.messageSending = true

        const textMessage = new CometChat.TextMessage(
          receiverId,
          messageInput,
          receiverType
        )

        if (this.parentMessageId) {
          textMessage.setParentMessageId(this.parentMessageId)
        }

        const message = await CometChat.sendMessage(textMessage)

        this.playAudio()
        this.replyPreview = null
        this.emitAction('messageComposed', { messages: [message] })
      } catch (e) {
        this.logError(e)
      } finally {
        this.messageSending = false
      }
    },
    /**
     * Sends text message
     */
    async sendTextMessage () {
      if (this.isBlockedByMe) return

      try {
        if (this.showEmojiPicker) {
          this.showEmojiPicker = false
        }

        if (!this.messageInput.trim().length) {
          return false
        }

        if (this.messageSending) {
          return false
        }

        this.messageSending = true

        if (this.messageToBeEdited) {
          this.editMessage()
          return false
        }

        const { receiverId, receiverType } = this.getReceiverDetails()

        const messageInput = this.messageInput.trim()
        const textMessage = new CometChat.TextMessage(
          receiverId,
          messageInput,
          receiverType
        )

        if (this.parentMessageId) {
          textMessage.setParentMessageId(this.parentMessageId)
        }

        this.endTyping()

        const message = await CometChat.sendMessage(textMessage)

        this.messageInput = ''
        this.updateMessageTextDOM('')

        this.playAudio()
        this.emitAction('messageComposed', { messages: [message] })
      } catch (e) {
        this.logError(e)
      } finally {
        this.messageSending = false
      }
    },
    /**
     * Edits message
     */
    async editMessage () {
      const messageToBeEdited = this.messageToBeEdited

      try {
        const { receiverId, receiverType } = this.getReceiverDetails()

        const messageText = this.messageInput.trim()
        const textMessage = new CometChat.TextMessage(
          receiverId,
          messageText,
          receiverType
        )

        textMessage.setId(messageToBeEdited.id)

        this.endTyping()

        const message = await CometChat.editMessage(textMessage)
        this.playAudio()
        this.messageSending = false

        this.closeEditPreview()

        this.emitAction('messageEdited', { message })
      } catch (e) {
        this.messageSending = false
        this.logError(e)
      }
    },
    /**
     * Toggles emoji picker
     */
    toggleEmojiPicker () {
      if (this.isBlockedByMe) return
      this.showEmojiPicker = !this.showEmojiPicker
    },
    /**
     * Updates text content of inout in DOM
     */
    updateMessageTextDOM (text = '') {
      this.$nextTick(() => {
        if (this.$refs && this.$refs.messageInputRef) {
          this.$refs.messageInputRef.textContent = text
        }
      })
    },
    /**
     * Gets receiver details
     */
    getReceiverDetails () {
      let receiverId
      let receiverType
      try {
        if (this.type === 'user') {
          receiverId = this.item.uid
          receiverType = CometChat.RECEIVER_TYPE.USER
        } else if (this.type === 'group') {
          receiverId = this.item.guid
          receiverType = CometChat.RECEIVER_TYPE.GROUP
        }

        return { receiverId, receiverType }
      } catch (error) {
        console.error(error)
      }
    },
    /**
     * Plays composer message sound
     */
    playAudio () {
      if (this.canPlayAudio) {
        this.audio.currentTime = 0
        this.audio.play()
      }
    }
  },
  mounted () {
    this.audio = new Audio(outgoingMessageAlert)
  }
}
</script>
