<template>
  <div class="block-layout height-js no-min">
    <div class="block-layout_scroll">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  mounted () {
    this.$utils.scrollHG()
    window.addEventListener('resize', this.$utils.scrollHG)
  }
}
</script>
<style lang="scss">
.block-layout{
  &_scroll{
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 12px;
    margin: 0 -12px;
  }
}
</style>
