<template>
  <div class="lb-w-chat height-js no-min" data-bt="25">
    <chat-comet-header
      :item="item"
      :type="type"
      :logged-in-user="loggedInUser"
      @action="actionHandler"
    ></chat-comet-header>
    <char-comet-list
      :item="item"
      :type="type"
      :messages="messageList"
      :logged-in-user="loggedInUser"
      @action="actionHandler"
    ></char-comet-list>
    <chat-comet-composer
      :item="item"
      :type="type"
      :smart-reply="replyPreview"
      :message-to-edit="messageToBeEdited"
      @action="actionHandler"
    />
    <div class="callalert__wrapper">
      <!-- <chat-comet-incoming-call @action="actionHandler"/> -->
      <chat-comet-outgoing-call
        :item="item"
        :type="type"
        :incoming-call="incomingCall"
        :outgoing-call="outgoingCall"
        :logged-in-user="loggedInUser"
        @action="actionHandler"
      />
    </div>
  </div>
</template>
<script>
import { CometChat } from '@cometchat-pro/chat'

import { DEFAULT_ARRAY_PROP, DEFAULT_OBJECT_PROP, DEFAULT_STRING_PROP, DEFAULT_BOOLEAN_PROP } from '../../resources/constants'

import * as enums from '../../util/enums'
import { cometChatMessage, cometChatScreens } from '../../mixins/'

import ChatCometHeader from './ChatCometHeader/index'
import CharCometList from './ChatCometList/index'
import ChatCometComposer from './ChatCometComposer'

/* import ChatCometIncomingCall from './ChatCometCalls/ChatCometIncomingCall/index' */
import ChatCometOutgoingCall from './ChatCometCalls/ChatCometOutgoingCall/index'

import { incomingMessageAlert } from '../../resources/audio/'
import { CometChatEvent } from '@/util/CometChatEvent'
import { CometChatManager } from '@/util/controller'

/**
 * Displays message list with message composer and header.
 */
export default {
  name: 'ChatComet',
  mixins: [cometChatMessage, cometChatScreens],
  components: {
    ChatCometHeader,
    CharCometList,
    ChatCometComposer,
    /* ChatCometIncomingCall, */
    ChatCometOutgoingCall
  },
  props: {
    /**
     * The selected chat item object.
     */
    item: { ...DEFAULT_OBJECT_PROP },
    /**
     * Type of chat item.
     */
    type: { ...DEFAULT_STRING_PROP },
    /**
     * Wheter to show sidebar.
     */
    sidebar: { ...DEFAULT_BOOLEAN_PROP },
    /**
     * List of group messages.
     */
    groupMessages: { ...DEFAULT_ARRAY_PROP },
    /**
     * @ignore
     */
    messageconfig: { ...DEFAULT_OBJECT_PROP },
    /**
     * Action data from listener.
     */
    actionFromListener: { ...DEFAULT_OBJECT_PROP },
    /**
     * The composed thread message.
     */
    composedThreadMessage: { ...DEFAULT_OBJECT_PROP },
    /**
     * The type of reaction.
     */
    reaction: { ...DEFAULT_STRING_PROP, default: 'heart' }
  },
  data () {
    return {
      messageList: [],
      unreadMessages: [],
      replyPreview: null,
      scrollToBottom: true,
      reactionName: 'heart',
      messageToBeEdited: null,
      showLiveReaction: false,

      loggedInUser: null,
      incomingCall: null,
      outgoingCall: null,
      callMessage: {}
    }
  },
  watch: {
    /**
     * One true watcher that updates state on props update.
     */
    propsForWatcher: {
      handler (_, prevProps) {
        const idKey = this.type === 'user' ? 'uid' : 'guid'

        if (prevProps.item[idKey] !== this.item[idKey]) {
          this.messageList = []
          this.unreadMessages = []
          this.scrollToBottom = true
          this.messageToBeEdited = null
        } else if (prevProps.type !== this.type) {
          this.messageList = []
          this.unreadMessages = []
          this.scrollToBottom = true
          this.messageToBeEdited = null
        } else if (
          prevProps.composedThreadMessage !== this.composedThreadMessage
        ) {
          this.updateReplyCount(this.composedThreadMessage)
        } else if (prevProps.callMessage !== this.callMessage) {
          this.actionHandler({
            action: 'callUpdated',
            message: this.callMessage
          })
        } else if (prevProps.actionFromListener !== this.actionFromListener) {
          this.actionHandler({ ...this.actionFromListener })
        } else if (prevProps.groupMessages !== this.groupMessages) {
          this.appendMessage(this.groupMessages)
        }
      },
      deep: true
    }
  },
  computed: {
    /**
     * Whether it can show live reactions.
     */
    canShowLiveReaction () {
      return this.item.blockedByMe ? false : this.showLiveReaction
    },
    /**
     * Whether it can show new message button.
     */
    canShowNewMessageIndicator () {
      return this.unreadMessages.length > 0 ? true : false
    },
    /**
     * Computed object, made of props, for watcher.
     */
    propsForWatcher () {
      return {
        item: this.item,
        type: this.type,
        callMessage: this.callMessage,
        groupMessages: this.groupMessages,
        actionFromListener: this.actionFromListener,
        composedThreadMessage: this.composedThreadMessage
      }
    },

    incoming_call () {
      return this.$store.getters.incoming_call
    }
  },
  methods: {
    /**
     * Handler for action events
     */
    actionHandler ({ action, item, call, type, count, group, members, message, messages, incomingCall, rejectedCall }) {
      switch (action) {
        case 'item-click':
          this.emitAction(action, { item, type })
          break
        case 'customMessageReceived':
        case 'messageReceived':
          if (messages[0].parentMessageId) {
            this.updateReplyCount(messages)
          } else {
            if (messages[0].type !== 'extension_poll') {
              this.smartReplyPreview(messages)
            }
            this.appendMessage(messages)
          }
          this.playAudio()
          break
        case 'messageComposed':
          this.appendMessage(messages)
          this.emitAction(action, { messages })
          break
        case 'messageUpdated':
          this.scrollToBottom = false
          this.updateMessages(messages)
          break
        case 'messageFetched':
          this.prependMessages(messages)
          break
        case 'messageRefreshed':
          this.messageList = [...messages]
          break
        case 'messageFetchedAgain':
          this.scrollToBottom = true
          this.prependMessages(messages)
          break
        case 'messageDeleted':
          this.removeMessages(messages)
          break
        case 'blockUser':
          this.blockUser()
          break
        case 'unblockUser':
          this.unblockUser()
          break
        case 'audioCall':
          this.audioCall()
          break
        case 'videoCall':
          this.videoCall()
          break
        case 'viewDetail':
        case 'closeDetailClicked':
          this.toggleDetailView()
          break
        case 'menuClicked':
          this.item = {}
          this.toggleSideBar()
          break
        case 'closeMenuClicked':
          this.toggleSideBar()
          break
        case 'groupUpdated':
          this.groupUpdated(item, count)
          break
        case 'groupDeleted':
          this.deleteGroup(group)
          break
        case 'leftGroup':
          this.leaveGroup(group)
          break
        case 'membersUpdated':
          this.updateMembersCount(count)
          break
        case 'viewMessageThread':
          this.viewMessageThread(message)
          break
        case 'closeThreadClicked':
          this.closeThreadMessages()
          break
        case 'threadMessageComposed':
          this.onThreadMessageComposed(messages)
          this.updateLastMessage(messages[0])
          break
        case 'acceptIncomingCall':
          this.acceptIncomingCall(incomingCall)
          break
        case 'acceptedIncomingCall':
          this.callInitiated(call)
          break
        case 'rejectedIncomingCall':
          this.rejectedIncomingCall(incomingCall, rejectedCall)
          break
        case 'outgoingCallRejected':
        case 'outgoingCallCancelled':
        case 'callEnded':
          this.outgoingCallEnded(call)
          break
        case 'userJoinedCall':
        case 'userLeftCall':
          this.appendCallMessage(call)
          break
        case 'viewActualImage':
          this.setImageView(message)
          break
        case 'closeActualImage':
          this.setImageView(null)
          break
        case 'membersAdded':
          this.membersAdded(members, message)
          break
        case 'memberUnbanned':
          this.memberUnbanned(members, message)
          break
        case 'memberScopeChanged':
          this.memberScopeChanged(members, message)
          break
        case 'lastMessageEdited':
        case 'lastMessageDeleted':
          this.updateLastMessage(messages[0])
          break
        case 'listenerData':
          this.actionFromListener = { action: action, messages: [...messages] }
          break
        default:
          break
      }
    },
    /**
     * Toggles reaction view
     */
    toggleReaction (flag) {
      this.showLiveReaction = flag
    },
    /**
     * Shows reaction
     */
    showReaction (reaction) {
      if (!this.hasProperty(reaction, 'data')) {
        return
      }

      if (
        !this.hasProperty(reaction.data || {}, 'type') ||
        !this.hasProperty(reaction.data || {}, 'reaction')
      ) {
        return;
      }

      if (!this.hasProperty(enums.LIVE_REACTIONS, reaction.data.reaction)) {
        return;
      }

      if (reaction.data.type === enums.LIVE_REACTION_KEY) {
        this.reactionName = reaction.data.reaction
        this.showLiveReaction = true
      }
    },
    /**
     * Updates reply count
     */
    updateReplyCount (messages) {
      const receivedMessage = messages[0]
      const messageList = [...this.messageList]
      const parentMessageId = receivedMessage.parentMessageId ?? null

      const messageKey = messageList.findIndex((m) => m.id === parentMessageId)

      if (messageKey > -1) {
        const messageObj = messageList[messageKey]
        let replyCount = messageObj.replyCount ? messageObj.replyCount : 0
        replyCount = replyCount + 1

        const newMessageObj = Object.assign({}, messageObj, {
          replyCount: replyCount
        })

        messageList.splice(messageKey, 1, newMessageObj)
        this.messageList = messageList
        this.scrollToBottom = false
      }
    },
    /**
     * Refreshing messages
     */
    refreshingMessages () {
      this.messageList = []
      this.messageToBeEdited = ''
      this.replyPreview = null
      this.liveReaction = false
      this.messageToReact = null
      CometChatEvent.triggerHandler(enums.EVENTS['CLEAR_UNREAD_MESSAGES_TRIGGERED'], {})
    },
    /**
     *
     * Handle new messages
     */
    newMessagesArrived (newMessage) {
      const unreadMessages = [...this.unreadMessages]
      unreadMessages.push(newMessage[0])
      this.unreadMessages = unreadMessages

      CometChatEvent.triggerHandler(enums.EVENTS['NEW_MESSAGES_TRIGGERED'], { unreadMessages: unreadMessages })
    },
    /**
     * Jump to new messages on click of new message button
     */
    jumpToMessages () {
      if (this.unreadMessages.length === 0) {
        return false
      }
      const unreadMessages = [...this.unreadMessages]
      let messageList = [...this.messageList]
      messageList = messageList.concat(unreadMessages)

      CometChatEvent.triggerHandler(enums.EVENTS["CLEAR_UNREAD_MESSAGES_TRIGGERED"], {})

      if (messageList.length > enums.EVENTS["MAX_MESSAGE_COUNT"]) {
        if (this.$refs.messageListRef) {
          this.$refs.messageListRef.reInitializeMessageBuilder()
        }
      } else {
        this.markMessagesAsRead(true)
      }
    },
    /**
     * Mark new messages as read
     */
    markMessagesAsRead (scrollToBottom) {
      if (this.unreadMessages.length === 0) {
        return false
      }

      const unreadMessages = [...this.unreadMessages]
      const messageList = [...this.messageList]

      unreadMessages.forEach(unreadMessage => {
        if (unreadMessage.receiverType === CometChat.RECEIVER_TYPE.USER) {
          if (this.$refs.messageListRef) {
            messageList.push(unreadMessage)
            this.$refs.messageListRef.markMessageAsRead(unreadMessage)
          }
        } else if (unreadMessage.receiverType === CometChat.RECEIVER_TYPE.GROUP) {
          if (this.$refs.messageListRef) {
            messageList.push(unreadMessage)
            this.$refs.messageListRef.markMessageAsRead(unreadMessage)
          }
        }
      })

      this.messageList = messageList
      this.scrollToBottom = scrollToBottom
      this.unreadMessages = []
    },
    callUpdated (message) {
      this.appendMessage([message])
    },
    groupUpdated (message, key, group, options) {
      this.appendMessage([message])

      this.emitAction('groupUpdated', { message, key, group, options })
    },
    /**
     * Updates poll message
     */
    updatePollMessage (message) {
      this.findMessage(message, (messageKey, messageList) => {
        const messageObj = messageList[messageKey]

        const metadataObj = {
          '@injected': { extensions: { polls: message.poll } }
        }

        const newMessageObj = { ...messageObj, metadata: metadataObj }

        messageList.splice(messageKey, 1, newMessageObj)
        this.updateMessages(messageList)
      })
    },
    /**
     * Plays audio
     */
    playAudio () {
      if (this.canPlayAudio) {
        this.audio.currentTime = 0
        this.audio.play()
      }
    },
    // scrollMessageList() {
    //   this.$nextTick(() => {
    //     if (this.$refs && this.$refs.messageListRef) {
    //       this.$refs.messageListRef.scrollListToBottom();
    //     }
    //   });
    // },
    /**
     * CometChat Events Listeners
     */
    cometChatEventListeners () {
      CometChatEvent.on(enums.EVENTS["NEW_MESSAGES"], (args) => this.newMessagesArrived(args.messages))
      CometChatEvent.on(enums.EVENTS["REFRESHING_MESSAGES"], () => this.refreshingMessages())
      CometChatEvent.on(enums.EVENTS["CLEAR_UNREAD_MESSAGES"], () => this.jumpToMessages())
      CometChatEvent.on(enums.EVENTS["NEW_MESSAGE_CLICKED"], () => this.jumpToMessages())
    }
  },
  mounted () {
    this.$utils.scrollHG()
    window.addEventListener('resize', this.$utils.scrollHG)
    this.reactionName = this.reaction
    this.audio = new Audio(incomingMessageAlert)
    this.cometChatEventListeners()
    this.$store.commit('toggle_chat_active', true)
    this.$root.$refs.chat = this
  },
  beforeMount () {
    (async () => {
      try {
        this.loggedInUser = await new CometChatManager().getLoggedInUser()
      } catch (e) {
        this.logError(e)
      }
    })()
  },
  destroyed () {
    this.$store.commit('toggle_chat_active', false)
  }
}
</script>
