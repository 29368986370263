<template>
  <div class="lb-w-chat-header">
    <div class="lb-w-chat-user">
      <div class="image">
        <img v-if="item.avatar" :src="item.avatar" alt="image-user">
        <img src="@/assets/img/default-user-avatar.png" alt="image-user">
      </div>
      <div class="info">
        <div class="h4 color-dark">{{item.name}}</div>
        <div class="color-grey text-md">{{status}}</div>
      </div>
    </div>
    <div class="lb-w-chat-nav">
      <div class="btn-wrapper">
        <button
          class="btn btn-ghost btn-icon tooltips-ms"
          @click="emitAction('audioCall')"
        >
          <img src="@/assets/img/icons/icon-phone.svg" alt="icon">
          <div class="tooltips"><span>Сall</span></div>
        </button>
        <button
          class="btn btn-ghost btn-icon tooltips-ms"
          @click="emitAction('videoCall')"
        >
          <img src="@/assets/img/icons/icon-videocam.svg" alt="icon">
          <div class="tooltips"><span>Video call</span></div>
        </button>
        <div class="dropdown right no-arrow">
          <span class="dropdown-overlay"></span>
          <button class="btn btn-ghost btn-icon">
            <img src="@/assets/img/icons/icon-more_horiz.svg" alt="icon">
          </button>
          <ul class="dropdown-inner">
            <li><span>Delete</span></li>
            <li><span>Label</span></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<!--eslint-disable-->
<script>
import dateFormat from "dateformat";

import { COMETCHAT_CONSTANTS, DEFAULT_STRING_PROP, DEFAULT_OBJECT_PROP } from '../../../resources/constants'

import { cometChatCommon, propertyCheck } from '../../../mixins/'
import { MessageHeaderManager } from './controller'

let messageHeaderManager

/**
 * Displays message info in header.
 */
export default {
  name: "ChatCometHeader",
  mixins: [ propertyCheck, cometChatCommon],
  props: {
    item: { ...DEFAULT_OBJECT_PROP },
    type: { ...DEFAULT_STRING_PROP },
    loggedInUser: { ...DEFAULT_OBJECT_PROP },
  },
  data() {
    return {
      status: "",
      presence: "offline",
    };
  },
  watch: {
    item: {
      /**
       * Watches item to update status message for group.
       */
      handler(newValue, oldValue) {
        if (messageHeaderManager) {
          messageHeaderManager.removeListeners();
        }
        messageHeaderManager = new MessageHeaderManager();
        messageHeaderManager.attachListeners(this.updateHeaderHandler);

        if (this.type === "user" && oldValue.uid !== newValue.uid) {
          this.setStatusForUser();
        } else if (
          this.type === "group" &&
          (newValue.guid !== oldValue.guid ||
            (newValue.guid === oldValue.guid &&
              newValue.membersCount !== oldValue.membersCount))
        ) {
          this.setStatusForGroup();
        }
      },
      deep: true,
    },
  },
  computed: {
    /**
     * Returns if item is blocked by current user.
     */
    isBlockedByMe() {
      return this.item.blockedByMe;
    },
    /**
     * Returns if audio call icon can be shown.
     */
    canShowAudioCall() {
      return this.isBlockedByMe ? false : true;
    },
    /**
     * Returns if video call icon can be shown.
     */
    canShowVideoCall() {
      return this.isBlockedByMe ? false : true;
    },
  },
  methods: {
    /**
     * Sets status message for user
     */
    setStatusForUser() {
      let status = this.item.status;
      const presence = this.item.status === "online" ? "online" : "offline";

      if (this.item.status === "offline" && this.item.lastActiveAt) {
        status =
          COMETCHAT_CONSTANTS.LAST_ACTIVE_AT +
          dateFormat(this.item.lastActiveAt * 1000, "d mmmm yyyy, h:MM TT");
      } else if (this.item.status === "offline") {
        status = "offline";
      }

      this.status = status;
      this.presence = presence;
    },
    /**
     * Sets status message for group
     */
    setStatusForGroup() {
      this.status = `${this.item.membersCount} members`;
    }
  },
  beforeMount() {
    if (this.type === 'user') {
      this.setStatusForUser();
    } else {
      this.setStatusForGroup();
    }
  }
};
</script>
