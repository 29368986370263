<template>
  <div class="accordion-item type-two" :class="{'is-active': open}">
    <div class="accordion-header">
      <span class="h4 color-grey">{{titleText}}</span>
      <span v-if="number > 0" class="badge-ml bg-grey color-grey-3 text-sm cirle">{{number}}</span>
      <button class="btn btn-accordion" @click="open = !open"><img src="@/assets/img/icons/icon-arr.svg" alt="icon"></button>
    </div>
    <VueSlideToggle :open="open" tag="section" :duration="500">
      <slot></slot>
    </VueSlideToggle>
  </div>
</template>

<script>
import { VueSlideToggle } from 'vue-slide-toggle'
export default {
  props: {
    titleText: {
      type: String,
      default: 'title'
    },
    number: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      open: true
    }
  },
  components: {
    VueSlideToggle
  }
}
</script>
