<template>
  <div class="lb-w-chat-message-wrap own-message">
    <div class="lb-w-chat-message">
      <div class="text-in" v-html="getMessageText()"></div>
      <div class="time">
        <span>{{messageTime}}</span>
      </div>
      <div class="arrow"></div>
    </div>
  </div>
</template>
<script>
import twemoji from 'twemoji'

import { DEFAULT_STRING_PROP, DEFAULT_OBJECT_PROP } from '../../../resources/constants'

import { cometChatCommon, cometChatMessage, cometChatBubbles } from '../../../mixins/'
import { linkify } from '../../../util/common'

/**
 * Message bubble for received text messages.
 */
export default {
  name: 'ChatCometTextMessage',
  mixins: [cometChatCommon, cometChatMessage, cometChatBubbles],
  props: {
    /**
     * The selected chat item object.
     */
    item: { ...DEFAULT_OBJECT_PROP },
    /**
     * Type of chat item.
     */
    type: { ...DEFAULT_STRING_PROP },
    /**
     * Theme of the UI.
     */
    message: { ...DEFAULT_OBJECT_PROP },
    /**
     * Current logged in user.
     */
    loggedInUser: { ...DEFAULT_OBJECT_PROP },
    /**
     * Id of parent for the message.
     */
    parentMessageId: { ...DEFAULT_STRING_PROP }
  },
  data () {
    return {
      messageFrom: 'sender'
    }
  },
  methods: {
    /**
     * Returns parsed message text.
     */
    getMessageText () {
      return twemoji.parse(linkify(this.parsedMessage.text), {
        folder: 'svg',
        ext: '.svg'
      })
    }
  }
}
</script>
