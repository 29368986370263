<template>
  <div>
    <div class="lb-mb-15">
      <div class="row justify-content-between align-items-center">
        <div class="col-auto">
          <div class="h3 color-dark-2">Goals</div>
        </div>
        <div class="col-auto">
          <div class="row row-8 align-items-center">
            <div class="col-auto">
              <button
                @click="prevMonth"
                class="btn btn-ghost btn-icon"
              >
                <img src="@/assets/img/icons/icon-keyboard_arrow_left.svg" alt="icon">
              </button>
            </div>
            <div class="col-auto">
              <div class="date-min-width h4 color-dark">
                {{formDate.nameMonth}} {{formDate.year}}
              </div>
            </div>
            <div class="col-auto">
              <button
                @click="nextMonth"
                class="btn btn-ghost btn-icon"
              >
                <img src="@/assets/img/icons/icon-keyboard_arrow_right.svg" alt="icon">
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="new-task_wrapper">
      <div class="new-task_overlay" @click="hiddenSettings($event)"></div>
      <div class="new-task_input lb-mb-20">
        <div class="input-inner">
          <input
            :placeholder="'Add Task for' + formDate.nameMonth + ', press Enter to save'"
            class="input input-material typed"
            type="text"
            @focus=" showSettings($event)"
            v-model="goals_input"
            @keypress.enter="saveGoal($event)"
          >
        </div>
      </div>
      <div class="new-task_settings">
        <div class="h3 color-grey lb-mb-20">Settings</div>
        <div class="settings-task">
          <div class="label color-dark">Type task</div>
          <div class="content">
            <div class="settings-drop">
              <span class="settings-drop_overlay" @click="stDropClose($event)"></span>
              <div class="settings-drop_inner" @click="stDropOpen($event)">
                <span class="settings-drop_btn" v-if="!goals_model.type"><span class="icon"><img src="@/assets/img/icons/icon-add-09.svg" alt="icon"></span> Add type</span>
                <span v-else class="badge-ml bg-grey color-dark-2 text-sm">{{goal_types.find(e => e.id === goals_model.type).title}}</span>
              </div>
              <ul class="settings-drop_list">
                <li
                  v-for="(item, i) in goal_types"
                  :key="i + 'z'"
                  @click="goals_model.type = item.id; stDropClose($event)"
                  :class="{'is-active': goals_model.type === item.id}"
                >
                  <span class="badge-ml bg-grey color-dark-2 text-sm">{{item.title}}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="settings-task">
          <div class="label color-dark">Priority</div>
          <div class="content">
            <div class="settings-drop">
              <span class="settings-drop_overlay" @click="stDropClose($event)"></span>
              <div class="settings-drop_inner" @click="stDropOpen($event)">
                <span class="badge-ml bg-red text-sm" :class="statusClass(goals_model.priority)">{{goals_model.priority}}</span>
              </div>
              <ul class="settings-drop_list">
                <li
                  @click="goals_model.priority = 'High'; stDropClose($event)"
                  :class="{'is-active': goals_model.priority === 'High'}"
                >
                  <span class="badge-ml bg-red text-sm">High</span>
                </li>
                <li
                  @click="goals_model.priority = 'Medium'; stDropClose($event)"
                  :class="{'is-active': goals_model.priority === 'Medium'}"
                >
                  <span class="badge-ml bg-orang text-sm">Medium</span>
                </li>
                <li
                  @click="goals_model.priority = 'Low'; stDropClose($event)"
                  :class="{'is-active': goals_model.priority === 'Low'}"
                >
                  <span class="badge-ml bg-green text-sm">Low</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!loaderLocal">
      <div v-if="goals.uncompleted.length > 0 || goals.completed.length > 0">
        <div v-if="goals.uncompleted.length > 0" class="accordion-item type-two lb-mb-20">
          <accordin-slide title-text="Uncompleted" :number="goals.uncompleted.length">
            <div class="accordion-inner">
              <div v-for="(item, i) in goals.uncompleted" :key="i" class="goals-item">
                <div class="row align-items-center justify-content-between">
                  <div class="col-auto">
                    <label class="checkbox-entry redonly">
                      <input type="checkbox" class="subscribe-input" value="Screencast by Ilya Kantor" onclick="return false">
                      <span>{{item.title}}</span>
                    </label>
                  </div>
                  <div class="col-lg-4">
                    <div class="row row-12 nowrap align-items-center justify-content-end text-right">
                      <div class="col-auto">
                        <span v-if="item.type" class="badge-ml bg-grey color-dark-2 text-sm">{{ goal_types.find(e => e.id === item.type).title}}</span>
                      </div>
                      <div class="col-3">
                        <span v-if="item.priority" class="badge-ml bg-red color-dark-2 text-sm" :class="statusClass(item.priority)">{{item.priority}}</span>
                      </div>
                      <div class="col-auto">
                        <button
                          class="btn btn-ghost btn-icon"
                          @click="openEditGoals(item.id, true)"
                        >
                          <img src="@/assets/img/icons/icon-more_horiz.svg" alt="icon">
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </accordin-slide>
        </div>
        <div v-if="goals.completed.length > 0" class="accordion-item type-two lb-mb-20">
          <accordin-slide title-text="Completed" :number="goals.completed.length">
            <div class="accordion-inner">
              <div class="goals-item"  v-for="(item, i) in goals.completed" :key="i">
                <div class="row align-items-center justify-content-between">
                  <div class="col-auto">
                    <label class="checkbox-entry redonly">
                      <input type="checkbox" class="subscribe-input" value="Screencast by Ilya Kantor" checked onclick="return false">
                      <span>{{item.title}}</span>
                    </label>
                  </div>
                  <div class="col-lg-4">
                    <div class="row row-12 nowrap align-items-center justify-content-end text-right">
                      <div class="col-auto">
                        <span v-if="item.type" class="badge-ml bg-grey color-dark-2 text-sm">{{goal_types.find(e => e.id === item.type).title}}</span>
                      </div>
                      <div class="col-3">
                        <span v-if="item.priority" class="badge-ml bg-red color-dark-2 text-sm" :class="statusClass(item.priority)">{{item.priority}}</span>
                      </div>
                      <div class="col-auto">
                        <button
                          class="btn btn-ghost btn-icon"
                          @click="openEditGoals(item.id, false)"
                        >
                          <img src="@/assets/img/icons/icon-more_horiz.svg" alt="icon">
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </accordin-slide>
        </div>
      </div>
      <div v-else>None</div>
    </div>
    <div v-else class="loader-wrapper">
      <div class="loader-circle">
        <img src="@/assets/img/loader-icon.png" alt="loader">
      </div>
    </div>
    <pop-up
      :show-modal="showModal"
      slide-animation="slide"
      view-modal-container="modal-container"
      @closemodal="closeEditGoals"
    >
      <div class="h2 color-dark lb-mb-32">Edit task</div>
      <div v-if="goals_edit">
        <div class="lb-mb-15">
          <div class="h4 lb-mb-2">Task name</div>
          <div class="text-md color-grey">Task name that is visible to you and your client</div>
        </div>
        <div class="lb-mb-20">
          <input
            placeholder="title"
            class="input input-material typed"
            type="text"
            @focus="$v.goals_edit.title.$touch()"
            v-model="goals_edit.title"
            :class="{'error': $v.goals_edit.title.$error}"
          >
        </div>
        <div class="lb-mb-15">
          <div class="h4 lb-mb-2">Settings</div>
          <div class="text-md color-grey">Additional task parameters</div>
        </div>
        <div class="lb-mb-32">
          <div class="settings-task">
            <div class="label color-dark text-md">Type task</div>
            <div class="content">
              <div class="settings-drop">
                <span class="settings-drop_overlay" @click="stDropClose($event)"></span>
                <div class="settings-drop_inner" @click="stDropOpen($event)">
                  <span class="settings-drop_btn" v-if="!goals_edit.type"><span class="icon"><img src="@/assets/img/icons/icon-add-09.svg" alt="icon"></span> Add type</span>
                  <span v-else class="badge-ml bg-grey color-dark-2 text-sm">{{goal_types.find(e => e.id === goals_edit.type).title}}</span>
                </div>
                <ul class="settings-drop_list">
                  <li
                    v-for="(item, i) in goal_types"
                    :key="i + 'z'"
                    @click="goals_edit.type = item.id; stDropClose($event)"
                    :class="{'is-active': goals_edit.type === item.id}"
                  >
                    <span class="badge-ml bg-grey color-dark-2 text-sm">{{item.title}}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="settings-task">
            <div class="label color-dark text-md">Priority</div>
            <div class="content">
              <div class="settings-drop">
                <span class="settings-drop_overlay" @click="stDropClose($event)"></span>
                <div class="settings-drop_inner" @click="stDropOpen($event)">
                  <span class="badge-ml bg-red text-sm" :class="statusClass(goals_edit.priority)">{{goals_edit.priority}}</span>
                </div>
                <ul class="settings-drop_list">
                  <li
                    @click="goals_edit.priority = 'High'; stDropClose($event)"
                    :class="{'is-active': goals_edit.priority === 'High'}"
                  >
                    <span class="badge-ml bg-red text-sm">High</span>
                  </li>
                  <li
                    @click="goals_edit.priority = 'Medium'; stDropClose($event)"
                    :class="{'is-active': goals_edit.priority === 'Medium'}"
                  >
                    <span class="badge-ml bg-orang text-sm">Medium</span>
                  </li>
                  <li
                    @click="goals_edit.priority = 'Low'; stDropClose($event)"
                    :class="{'is-active': goals_edit.priority === 'Low'}"
                  >
                    <span class="badge-ml bg-green text-sm">Low</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-wrapper right">
          <button
            class="btn btn-secondary"
            @click="closeEditGoals"
          >
            Cancel
          </button>
          <button
            class="btn btn-primary btn-loader"
            :class="{'disable': $v.$invalid, 'loader': loadingSend}"
            @click="saveChanges"
          >
            Save changes
          </button>
        </div>
      </div>
    </pop-up>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import AccordinSlide from '@/components/AccordionSlideSC'
// import AccordinSlide from '@/components/AccordionSlide'
import PopUp from '@/components/PopUp'
export default {
  data () {
    return {
      showModal: false,
      goal_types: '',
      goals_input: '',
      currentDate: new Date(),
      loaderLocal: true,
      loadingSend: false,
      goals_edit: '',
      goals_model: {
        id: Date.now(),
        type: '',
        priority: 'High'
      }
    }
  },
  computed: {
    goals () {
      return this.$store.getters.goals
    },
    formDate () {
      return this.dateDay(this.currentDate)
    },
    active_user_id () {
      return this.$store.getters.activeUser
    }
  },
  methods: {
    handleEvents (events) {
      this.currentEvents = events
    },
    dateDay (e) {
      const t = new Date(e)
      var date = t.getFullYear() + '-' + (t.getMonth()) + '-' + t.getDate()
      return {
        date: date,
        day: t.getDate(),
        month: t.getMonth() + 1,
        year: t.getFullYear(),
        nameMonth: this.getNameMonth(t.getMonth())
      }
    },
    getNameMonth (e) {
      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      return months[e]
    },
    nextMonth () {
      this.loaderLocal = true
      if (this.currentDate.getMonth() === 11) {
        this.currentDate = new Date(this.currentDate.getFullYear() + 1, 0, 1)
      } else {
        this.currentDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 1)
      }
      this.fetchGoals({
        year: this.formDate.year,
        month: this.formDate.month
      })
    },
    prevMonth () {
      this.loaderLocal = true
      if (this.currentDate.getMonth() === 0) {
        this.currentDate = new Date(this.currentDate.getFullYear() - 1, 11, 1)
      } else {
        this.currentDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() - 1, 1)
      }
      this.fetchGoals({
        year: this.formDate.year,
        month: this.formDate.month
      })
    },
    statusClass (e) {
      switch (e) {
        case 'Height':
          return 'bg-red'
        case 'Low':
          return 'bg-green'
        case 'Medium':
          return 'bg-orang'
        default:
          return ''
      }
    },
    stDropOpen: function (e) {
      e.target.closest('.settings-drop').classList.add('active')
    },
    stDropClose: function (e) {
      e.target.closest('.settings-drop').classList.remove('active')
    },
    showSettings: function (e) {
      e.target.closest('.new-task_wrapper').classList.add('active')
    },
    hiddenSettings: function (e) {
      e.target.closest('.new-task_wrapper').classList.remove('active')
    },
    saveGoal (e) {
      this.loaderLocal = true
      if (this.goals_input.length > 0) {
        const c = JSON.parse(JSON.stringify(this.goals_model))
        c.date = this.formDate.year + '-' + this.formDate.month
        c.year = this.formDate.year
        c.month = this.formDate.month
        c.title = this.goals_input
        this.$store.dispatch('sendGoals', c)
          .finally(() => {
            this.loaderLocal = false
          })
        this.goals_input = ''
        this.hiddenSettings(e)
      }
    },
    closeEditGoals () {
      this.showModal = false
      this.goals_edit = ''
    },
    openEditGoals (id, a) {
      this.showModal = true
      this.goals_edit = JSON.parse(JSON.stringify(this.$store.getters.one_goals(id)))
      this.uncompleted = a
    },
    saveChanges () {
      this.loadingSend = true
      this.$store.dispatch('changeGoals', this.goals_edit)
        .finally(() => {
          this.loadingSend = false
          this.closeEditGoals()
        })
    },
    fetchGoals (e) {
      if (this.active_user_id) {
        this.loaderLocal = true
        this.$store.dispatch('fetchGoals', e)
          .finally(() => {
            this.loaderLocal = false
          })
      }
    }
  },
  mounted () {
    this.fetchGoals({
      year: this.formDate.year,
      month: this.formDate.month
    })
    this.$axios({
      method: 'get',
      url: this.$root.$data.baseURL + 'apicall/coach/getgoaltypes'
    })
      .then((r) => {
        this.goal_types = r.data
      })
      .catch(function (e) {
        console.error(e)
      })
  },
  watch: {
    active_user_id: function (v) {
      this.fetchGoals({
        year: this.formDate.year,
        month: this.formDate.month
      })
    }
  },
  validations: {
    goals_edit: {
      title: {
        required
      }
    }
  },
  components: {
    AccordinSlide,
    PopUp
  }
}
</script>

<style lang="scss">
@import "../assets/scss/_goals-other.scss";
</style>
