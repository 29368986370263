<template>
  <div>
    <div v-if="users.length > 0 && loggedInUser !== null">
      <div v-for="(item, i) in users" :key="i">
        <div
          class="users-list-item lb-mb-8"
          @click="userClickHandler(item)"
          :class="{'is-active': active_user_id === item.uid}"
        >
          <div class="row row-12 nowrap align-items-center">
            <div class="col-auto">
              <div class="image">
                <img v-if="item.avatar" :src="item.avatar" alt="image-user">
                <img v-else src="@/assets/img/default-user-avatar.png" alt="image-user">
              </div>
            </div>
            <div class="col-w-100">
              <div class="name">{{item.name}}</div>
              <div v-if="item.lastMessage" class="message text-sm">
                <template v-if="item.lastMessage.category === 'call'">
                  {{messageText(item.lastMessage)}}
                </template>
                <template v-if="item.lastMessage.category === 'message'">
                  <template
                    v-if="item.lastMessage.type === 'text' && item.lastMessage.text"
                  >
                    {{item.lastMessage.text}}
                  </template>
                  <template v-else-if="item.lastMessage.type === 'file' && item.lastMessage.data.attachments">
                    File
                  </template>
                  <template v-else-if="item.lastMessage.type === 'image' && item.lastMessage.data.url">
                    Image
                  </template>
                </template>
              </div>
            </div>
          </div>
          <!--
          <span v-if="item.unreadMessageCount > 0" class="badge-sm"><span>{{item.unreadMessageCount}}</span></span>
          -->
        </div>
        <hr v-if="users.length - 1 !== i" class="hr lb-mb-8">
      </div>
    </div>
    <div v-else class="text-center">none</div>
  </div>
</template>

<script>
import { cometChatCommon } from '@/mixins/'
import { CometChat } from '@cometchat-pro/chat'
import { COMETCHAT_CONSTANTS } from '@/resources/constants'
import { CometChatManager } from '@/util/controller'

export default {
  mixins: [cometChatCommon],
  props: {
    users: {
      type: Array
    },
    type: {
      type: String
    }
  },
  data () {
    return {
      loggedInUser: null
    }
  },
  computed: {
    active_user_id: function () {
      return this.$store.getters.activeUser
    }
  },
  methods: {
    messageText (e) {
      const call = e
      const loggedInUser = this.loggedInUser

      let message = null
      switch (call.status) {
        case CometChat.CALL_STATUS.INITIATED: {
          message = COMETCHAT_CONSTANTS.CALL_INITIATED
          if (call.type === CometChat.CALL_TYPE.AUDIO) {
            if (call.receiverType === CometChat.RECEIVER_TYPE.USER) {
              message =
                call.callInitiator.uid === loggedInUser.uid
                  ? COMETCHAT_CONSTANTS.OUTGOING_AUDIO_CALL
                  : COMETCHAT_CONSTANTS.INCOMING_AUDIO_CALL
            } else if (call.receiverType === CometChat.RECEIVER_TYPE.GROUP) {
              if (call.action === CometChat.CALL_STATUS.INITIATED) {
                message =
                  call.callInitiator.uid === loggedInUser.uid
                    ? COMETCHAT_CONSTANTS.OUTGOING_AUDIO_CALL
                    : COMETCHAT_CONSTANTS.INCOMING_AUDIO_CALL
              } else if (call.action === CometChat.CALL_STATUS.REJECTED) {
                message =
                  call.sender.uid === loggedInUser.uid
                    ? COMETCHAT_CONSTANTS.CALL_REJECTED
                    : `${call.sender.name} ${COMETCHAT_CONSTANTS.REJECTED_CALL}`
              }
            }
          } else if (call.type === CometChat.CALL_TYPE.VIDEO) {
            if (call.receiverType === CometChat.RECEIVER_TYPE.USER) {
              message =
                call.callInitiator.uid === loggedInUser.uid
                  ? COMETCHAT_CONSTANTS.OUTGOING_VIDEO_CALL
                  : COMETCHAT_CONSTANTS.INCOMING_VIDEO_CALL
            } else if (call.receiverType === CometChat.RECEIVER_TYPE.GROUP) {
              if (call.action === CometChat.CALL_STATUS.INITIATED) {
                message =
                  call.callInitiator.uid === loggedInUser.uid
                    ? COMETCHAT_CONSTANTS.OUTGOING_VIDEO_CALL
                    : COMETCHAT_CONSTANTS.INCOMING_VIDEO_CALL
              } else if (call.action === CometChat.CALL_STATUS.REJECTED) {
                message =
                  call.sender.uid === loggedInUser.uid
                    ? COMETCHAT_CONSTANTS.CALL_REJECTED
                    : `${call.sender.name} ${COMETCHAT_CONSTANTS.REJECTED_CALL}`
              }
            }
          }
          break
        }
        case CometChat.CALL_STATUS.ONGOING: {
          if (call.receiverType === CometChat.RECEIVER_TYPE.USER) {
            message = COMETCHAT_CONSTANTS.CALL_ACCEPTED
          } else if (call.receiverType === CometChat.RECEIVER_TYPE.GROUP) {
            if (call.action === CometChat.CALL_STATUS.ONGOING) {
              message =
                call.sender.uid === loggedInUser.uid
                  ? COMETCHAT_CONSTANTS.CALL_ACCEPTED
                  : `${call.sender.name} ${COMETCHAT_CONSTANTS.JOINED}`
            } else if (call.action === CometChat.CALL_STATUS.REJECTED) {
              message =
                call.sender.uid === loggedInUser.uid
                  ? COMETCHAT_CONSTANTS.CALL_REJECTED
                  : `${call.sender.name} ${COMETCHAT_CONSTANTS.REJECTED_CALL}`
            } else if (call.action === 'left') {
              message =
                call.sender.uid === loggedInUser.uid
                  ? `${COMETCHAT_CONSTANTS.YOU} ${COMETCHAT_CONSTANTS.LEFT_THE_CALL}`
                  : `${call.sender.name} ${COMETCHAT_CONSTANTS.LEFT_THE_CALL}`
            }
          }
          break
        }
        case CometChat.CALL_STATUS.UNANSWERED: {
          message = COMETCHAT_CONSTANTS.CALL_UNANSWERED
          if (
            call.type === CometChat.CALL_TYPE.AUDIO &&
            (call.receiverType === CometChat.RECEIVER_TYPE.USER ||
              call.receiverType === CometChat.RECEIVER_TYPE.GROUP)
          ) {
            message =
              call.callInitiator.uid === loggedInUser.uid
                ? COMETCHAT_CONSTANTS.UNANSWERED_AUDIO_CALL
                : COMETCHAT_CONSTANTS.MISSED_AUDIO_CALL
          } else if (
            call.type === CometChat.CALL_TYPE.VIDEO &&
            (call.receiverType === CometChat.RECEIVER_TYPE.USER ||
              call.receiverType === CometChat.RECEIVER_TYPE.GROUP)
          ) {
            message =
              call.callInitiator.uid === loggedInUser.uid
                ? COMETCHAT_CONSTANTS.UNANSWERED_VIDEO_CALL
                : COMETCHAT_CONSTANTS.MISSED_VIDEO_CALL
          }
          break
        }
        case CometChat.CALL_STATUS.REJECTED:
          message = COMETCHAT_CONSTANTS.CALL_REJECTED
          break
        case CometChat.CALL_STATUS.CANCELLED:
          message = COMETCHAT_CONSTANTS.CALL_CANCELLED
          break
        case CometChat.CALL_STATUS.ENDED:
          message = COMETCHAT_CONSTANTS.CALL_ENDED
          break
        case CometChat.CALL_STATUS.BUSY:
          message = COMETCHAT_CONSTANTS.CALL_BUSY
          break
        default:
          break
      }
      return message
    },
    userClickHandler (user) {
      this.$store.commit('updateUsersActiveUser', user)
      this.emitAction('item-click', { item: user, type: this.type })
    }
  },
  beforeMount () {
    (async () => {
      try {
        this.loggedInUser = await new CometChatManager().getLoggedInUser()
      } catch (e) {
        this.logError(e)
      }
    })()
  }
}
</script>
