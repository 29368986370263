<template>
  <div>
    <div class="h3 color-dark lb-mb-8">Payment history</div>
    <div v-if="loaderLocal">
      <table class="table type-two">
        <tbody>
        <tr class="h4 color-grey"><td>Date</td><td>Description</td><td>Amount</td><td></td></tr>
        <!--
        <tr v-for="(item, i) in payments" :key="i" class="color-dark">
          <td>{{item.date}}</td>
          <td>Payment for <a class="btn btn-link md" href="#">{{item.description}}</a></td>
          <td>${{item.amount}}</td>
          <td><a class="btn btn-link md" :href="item.invoiceLink">Download Invoice</a></td>
        </tr>
        -->
        </tbody>
      </table>
    </div>
    <div v-else class="loader-wrapper">
      <div class="loader-circle">
        <img src="@/assets/img/loader-icon.png" alt="loader">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loaderLocal: false
    }
  },
  computed: {
    payments () {
      return this.$store.getters.payments
    }
  },
  mounted () {
    this.$store.dispatch('fetchPayments')
      .finally(() => {
        this.loaderLocal = true
      })
  }
}
</script>
