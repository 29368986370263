<template>
  <div class="lb-w-chat-message-wrap">
    <div class="lb-w-chat-message">
      <div class="lb-w-chat-file-ms">
        <span class="icon">
          <img src="@/assets/img/icons/icon-document.svg" alt="icon">
        </span>
        <div class="info">
          <div class="name">{{parsedMessage.data.attachments[0].name}}</div>
          <div class="size"><span>MB - {{size_mb(parsedMessage.data.attachments[0].size)}}</span> <a class="btn btn-link" :href="parsedMessage.data.attachments[0].url">Download</a></div>
        </div>
      </div>
      <div class="time">
        <span>{{messageTime}}</span>
      </div>
      <div class="arrow"></div>
    </div>
  </div>
</template>
<script>
import { DEFAULT_OBJECT_PROP, DEFAULT_STRING_PROP } from '../../../resources/constants'

import { cometChatCommon, cometChatBubbles } from '../../../mixins/'

import { CometChat } from '@cometchat-pro/chat'

/**
 * Message bubble for received image messages.
 */
export default {
  name: 'ChatCometFileMessage',
  mixins: [cometChatCommon, cometChatBubbles],
  props: {
    message: { ...DEFAULT_OBJECT_PROP },
    loggedInUser: { ...DEFAULT_OBJECT_PROP },
    parentMessageId: { ...DEFAULT_STRING_PROP }
  },
  data () {
    return {
      messageFrom: 'receiver'
    }
  },
  computed: {
    COMET_CHAT () {
      return CometChat
    }
  },
  methods: {
    size_mb: function (e) {
      return Math.ceil(e / (1024 * 1024))
    }
  }
}
</script>
