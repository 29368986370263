<template>
  <div
    class="lb-w-chat-pop-up"
    :class="{'video-chat': callInProgress.type === 'video'}"
    v-if="callInProgress"
  >
    <div class="lb-w-chat-pop-up-btn">
      <!-- :class="{'is-active': tab_show === 'video'}" -->
      <button
        class="btn btn-outlined"
        v-if="tab_show !== 'video'"
        @click="tab_show = 'video'"
      >
        call
      </button>
      <!-- :class="{'is-active': tab_show === 'chat'}" -->
      <button
        class="btn btn-outlined"
        v-if="tab_show !== 'chat'"
        @click="tab_show = 'chat'"
      >
        Back to chat
      </button>
    </div>
    <div
      class="lb-w-chat-pop-up-inner"
      :class="{'hidden-pop-up': tab_show === 'chat'}"
    >
      <div class="navigation-top">
        <!--
        <button
          class="btn btn-outlined"
          @click="cancelCall"
        >
          Back to chat
        </button>
        -->
      </div>
      <div class="lb-w-chat-window">
        <div class="lb-w-chat-video" ref="callScreenFrame"></div>
        <div class="nv-top">
          <!--
          <button class="btn btn-cr-icon">
            <span class="icon"><img src="@/assets/img/icons/icon-push_pin.svg" alt="image"></span>
          </button>
          <button class="btn btn-cr-icon">
            <span class="icon"><img src="@/assets/img/icons/icon-more.svg" alt="image"></span>
          </button>
          -->
        </div>
        <div v-if="loggedInUser.uid === callInProgress.receiver.uid" class="user-image">
          <img v-if="callInProgress.sender.avatar" :src="callInProgress.sender.avatar" alt="image">
          <img v-else src="@/assets/img/default-user-avatar.png" alt="image">
        </div>
        <div v-else class="user-image">
          <img v-if="callInProgress.receiver.avatar" :src="callInProgress.receiver.avatar" alt="image">
          <img v-else src="@/assets/img/default-user-avatar.png" alt="image">
        </div>
        <div class="nv-bottom">
          <div class="name badge sm" v-if="loggedInUser.uid === callInProgress.receiver.uid">{{ callInProgress.sender.name }}</div>
          <div class="name badge sm" v-else>{{ callInProgress.receiver.name }}</div>
          <!--
          <button
            class="btn btn-cr-icon"
          >
            <span class="icon on"><img src="@/assets/img/icons/icon-song-on.svg" alt="image"></span>
            <span class="icon off"><img src="@/assets/img/icons/icon-song-off.svg" alt="image"></span>
          </button>
          -->
        </div>
      </div>
      <div class="navigation-bottom">
        <button
          class="btn btn-cr-icon v-mr-8"
          :class="{'line-through': !is_audio}"
          @click="switchAudio"
        >
          <span class="icon on"><img src="@/assets/img/icons/icon-song-on.svg" alt="image"></span>
          <span class="icon off"><img src="@/assets/img/icons/icon-song-off.svg" alt="image"></span>
        </button>
        <button
          v-if="callInProgress.type === 'video'"
          class="btn btn-cr-icon v-mr-8"
          :class="{'line-through': !is_video}"
          @click="switchVideo"
        >
          <span class="icon on"><img src="@/assets/img/icons/icon-video-on.svg" alt="image"></span>
          <span class="icon off"><img src="@/assets/img/icons/icon-vide-off.svg" alt="image"></span>
        </button>
        <!--
        <button class="btn btn-cr-icon v-mr-24">
          <span class="icon"><img src="@/assets/img/icons/icon-more.svg" alt="image"></span>
        </button>
        -->
        <button
          class="btn btn-cr-icon red"
          @click="cancelCall"
        >
          <span class="icon"><img src="@/assets/img/icons/icon-call-end.svg" alt="image"></span>
        </button>
      </div>
    </div>
  </div>
</template>

<!--eslint-disable-->
<script>
import { CometChat } from "@cometchat-pro/chat"

import { DEFAULT_OBJECT_PROP, DEFAULT_STRING_PROP } from '../../../../resources/constants'

import { cometChatCommon, propertyCheck } from '../../../../mixins/'

import { CometChatManager } from '../../../../util/controller'
import { SvgAvatar } from '../../../../util/svgavatar'
import * as enums from '../../../../util/enums.js'

import { CallScreenManager } from './controller'

import { outgoingCallAlert } from '../../../../resources/audio/'

let outgoingAlert
let callScreenManager

/**
 * Displays the outgoing call window.
 */
export default {
  name: "CometChatOutgoingCall",
  mixins: [cometChatCommon, propertyCheck],
  props: {
    /**
     * The selected chat item object.
     */
    item: { ...DEFAULT_OBJECT_PROP },
    /**
     * Type of chat item.
     */
    type: { ...DEFAULT_STRING_PROP },
    /**
     * Incoming call message object.
     */
    incomingCall: { ...DEFAULT_OBJECT_PROP },
    /**
     * Outgoing call message object.
     */
    outgoingCall: { ...DEFAULT_OBJECT_PROP },
    /**
     * Current logged in user.
     */
    loggedInUser: { ...DEFAULT_OBJECT_PROP }
  },
  data() {
    return {
      tab_show: 'video',
      errorScreen: false,
      errorMessage: null,
      callInProgress: null,
      outgoingCallScreen: false,

      is_audio: false,
      is_video: false
    };
  },
  computed: {
    callController: function () {
      return CometChat.CallController.getInstance()
    }
  },
  watch: {
    /**
     * Updates state on outgoing call chnage.
     */
    outgoingCall: {
      handler(newValue, oldValue) {
        if (oldValue !== newValue && newValue) {
          this.playOutgoingAlert();

          let call = newValue;

          if (
            call.receiverType === CometChat.RECEIVER_TYPE.GROUP &&
            this.hasProperty(call.receiver, "icon") === false
          ) {
            const uid = call.receiver.guid;
            const char = call.receiver.name.charAt(0).toUpperCase();

            call.receiver.icon = SvgAvatar.getAvatar(uid, char);
          } else if (
            call.receiverType === CometChat.RECEIVER_TYPE.USER &&
            this.hasProperty(call.receiver, "avatar") === false
          ) {
            const uid = call.receiver.uid;
            const char = call.receiver.name.charAt(0).toUpperCase();

            call.receiver.avatar = SvgAvatar.getAvatar(uid, char);
          }

          this.outgoingCallScreen = true;
          this.callInProgress = call;
          this.errorScreen = false;
          this.errorMessage = null;
        }
      },
      deep: true,
    },
    /**
     * Accepts call when change in incoming call is detected.
     */
    incomingCall: {
      handler(newValue, oldValue) {
        if (oldValue !== newValue && newValue) {
          this.acceptCall();
        }
      },
      depp: true,
    },
  },
  methods: {
    switchAudio() {
      if (this.is_audio) {
        this.callController.muteAudio(false)
        this.is_audio = false
      } else {
        this.callController.muteAudio(true)
        this.is_audio = true
      }
    },
    switchVideo() {
      if (this.is_video) {
        this.callController.pauseVideo(false)
        this.is_video = false
      } else {
        this.callController.pauseVideo(true)
        this.is_video = true
      }
    },
    /**
     * Function that handles the call listeners
     */
    callScreenUpdateHandler(key, call) {
      switch (key) {
        case enums.INCOMING_CALL_CANCELLED:
          this.callInProgress = null;
          this.outgoingCallScreen = false;
          break;
        case enums.OUTGOING_CALL_ACCEPTED:
          if (this.outgoingCallScreen === true) {
            this.pauseOutgoingAlert();

            this.outgoingCallScreen = false;
            this.callInProgress = call;
            this.startCall(call);
          }
          break;
        case enums.OUTGOING_CALL_REJECTED:
          if (call.status && call.status === CometChat.CALL_STATUS.BUSY) {
            this.errorScreen = true;
            this.errorMessage = `${call.sender.name} is on another call.`;
          } else {
            this.pauseOutgoingAlert();

            this.emitAction("outgoingCallRejected", { call });

            this.callInProgress = null;
            this.outgoingCallScreen = false;
          }
          break;
        default:
          break;
      }
    },
    /**
     * Function to accept call
     */
    async acceptCall() {
      try {
        const call = await CometChatManager.acceptCall(
          this.incomingCall.sessionId
        );

        if (
          this.hasProperty(call.receiver, "uid") &&
          this.hasProperty(call.receiver, "avatar") === false
        ) {
          const uid = call.receiver.uid;
          const char = call.receiver.name.charAt(0).toUpperCase();

          call.receiver.avatar = SvgAvatar.getAvatar(uid, char);
        } else if (
          this.hasProperty(call.receiver, "guid") &&
          this.hasProperty(call.receiver, "icon") === false
        ) {
          const guid = call.receiver.guid;
          const char = call.receiver.name.charAt(0).toUpperCase();

          call.receiver.icon = SvgAvatar.getAvatar(guid, char);
        }

        this.emitAction("acceptedIncomingCall", { call });

        this.outgoingCallScreen = false;
        this.callInProgress = call;
        this.errorScreen = false;
        this.errorMessage = null;

        this.startCall(call);
      } catch (error) {
        this.logError("[CometChatOutgoingCall] acceptCall -- error", error);
        this.emitAction("callError", { error });
      }
    },
    /**
     * Function to cancel call
     */
    async cancelCall() {
      this.pauseOutgoingAlert()

      try {
        const call = await CometChatManager.rejectCall(
          this.callInProgress.sessionId,
          CometChat.CALL_STATUS.CANCELLED
        )
        this.emitAction("outgoingCallCancelled", { call })
      } catch (error) {
        this.emitAction("callError", { error })
      } finally {
        this.outgoingCallScreen = false
        this.callInProgress = null
      }
    },
    /**
     * Function to start call
     */
    startCall (call) {
      console.log('START CALL')
      this.$nextTick(() => {
        const $el = this.$refs.callScreenFrame;

        const sessionId = call.getSessionId();
        const audioOnly = call.type === CometChat.CALL_TYPE.AUDIO;

        const customCSS = '.tile-main-video-container { height: 100%; width: 100%; margin-right: 0; margin-left: 0; }' +
                          '.local-spotlight-stream-container{ display: none!important;} #video-container{display: none;}' +
                          '.main-video-name { color: #161616; background-color: #fff; position: absolute; top: auto; left: 18px; bottom: 18px; padding: 0 6px; font-weight: 400; border-radius: 6px; font-size: 14px;}'

        const callSettings = new CometChat.CallSettingsBuilder()
          .setSessionID(sessionId)
          .enableDefaultLayout(false)
          .showEndCallButton(false)
          .showPauseVideoButton(false)
          .showMuteAudioButton(false)
          .showScreenShareButton(false)
          .setMode(CometChat.CALL_MODE.SPOTLIGHT)
          .setIsAudioOnlyCall(audioOnly)
          .setCustomCSS(customCSS)
          .build();

        CometChat.startCall(
          callSettings,
          $el,
          new CometChat.OngoingCallListener({
            /*
            onUserJoined: (user) => {
              if (
                call.callInitiator.uid !== this.loggedInUser.uid &&
                call.callInitiator.uid !== user.uid
              ) {
                this.markMessageAsRead(call);

                const callMessage = {
                  category: call.category,
                  type: call.type,
                  action: call.action,
                  status: call.status,
                  callInitiator: call.callInitiator,
                  callReceiver: call.callReceiver,
                  receiverId: call.receiverId,
                  receiverType: call.receiverType,
                  sentAt: call.sentAt,
                  sender: { ...user },
                };

                this.emitAction("userJoinedCall", { call: callMessage });
              }
            },
            onUserLeft: (user) => {
              if (
                call.callInitiator.uid !== this.loggedInUser.uid &&
                call.callInitiator.uid !== user.uid
              ) {
                this.markMessageAsRead(call);

                const callMessage = {
                  category: call.category,
                  type: call.type,
                  action: "left",
                  status: call.status,
                  callInitiator: call.callInitiator,
                  callReceiver: call.callReceiver,
                  receiverId: call.receiverId,
                  receiverType: call.receiverType,
                  sentAt: call.sentAt,
                  sender: { ...user },
                };

                this.emitAction("userLeftCall", { call: callMessage });
              }
            },
            */
            onCallEnded: (endedCall) => {

              this.showOutgoingScreen = false;
              this.callInProgress = null;

              this.markMessageAsRead(endedCall);

              this.emitAction("callEnded", { call: endedCall });
            },
          })
        );
      })
    },
    /**
     * Marks a message as read
     */
    markMessageAsRead(message) {
      try {
        if (this.hasProperty(message, "readAt") === false) {
          CometChat.markAsRead(message);
        }
      } catch (error) {
        this.logError("Error marking message as read", error);
      }
    },
    /**
     * Plays the outgoing call alert
     */
    playOutgoingAlert () {
      try {
        outgoingAlert.currentTime = 0;
        if (typeof outgoingAlert.loop == "boolean") {
          outgoingAlert.loop = true;
        } else {
          outgoingAlert.addEventListener(
            "ended",
            function () {
              this.currentTime = 0;
              this.play();
            },
            false
          );
        }
        outgoingAlert.play();
      } catch (error) {
        this.logError("Alert play failed with error:", error);
      }
    },
    /**
     * Pauses the outgoing call alert
     */
    pauseOutgoingAlert () {
      try {
        outgoingAlert.pause();
      } catch (error) {
        this.logError("Alert pause failed with error:", error);
      }
    },
  },
  beforeMount () {
    outgoingAlert = new Audio(outgoingCallAlert)

    callScreenManager = new CallScreenManager()
    callScreenManager.attachListeners(this.callScreenUpdateHandler)
  },
  beforeDestroy () {
    if (callScreenManager) {
      callScreenManager.removeListeners()
      callScreenManager = null;
    }
  },
  beforeUnmount () {
    if (callScreenManager) {
      callScreenManager.removeListeners()
      callScreenManager = null
    }
  }
}
</script>
