<template>
  <div class="lb-w-chat-message-wrap own-message">
    <div class="lb-w-chat-message">
      <div class="lb-w-chat-image-ms">
        <a class="image-link" :href="imageUrl" target="_blank">
          <img :src="imageUrl" alt="image">
        </a>
      </div>
      <div class="time">
        <span>{{messageTime}}</span>
      </div>
      <div class="arrow"></div>
    </div>
  </div>
</template>
<script>
import { DEFAULT_OBJECT_PROP, DEFAULT_STRING_PROP } from '../../../resources/constants'

import { cometChatCommon, cometChatBubbles, thumbnailGeneration } from '../../../mixins/'

/**
 * Message bubble for received image messages.
 */
export default {
  name: 'ChatCometImageMessage',
  mixins: [cometChatCommon, cometChatBubbles, thumbnailGeneration],
  props: {
    message: { ...DEFAULT_OBJECT_PROP },
    loggedInUser: { ...DEFAULT_OBJECT_PROP },
    parentMessageId: { ...DEFAULT_STRING_PROP }
  },
  data () {
    return {
      imageUrl: ''
    }
  },
  mounted () {
    this.setImage()
  }
}
</script>
