var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"messagesEnd",staticClass:"lb-w-chat-scrolling height-js no-min",on:{"scroll":_vm.scrollHandler}},[_c('div',{staticClass:"lb-w-chat-body"},[(_vm.messages.length === 0)?_c('div',[(_vm.messagesLoading)?_c('div',[_vm._m(0)]):_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.decoratorMessage))])]):_c('div',[(_vm.messagesLoading)?_c('div',[_vm._m(1)]):_vm._e(),_vm._l((_vm.computedMessages),function(message,i){return [(message.messageSentDate)?_c('div',{key:i,staticClass:"text-center lb-mb-15"},[_c('span',{staticClass:"badge-ml bg-grey color-dark-2"},[_vm._v(" "+_vm._s(message.messageSentDate)+" ")])]):_vm._e(),(message.category === 'call')?[_c('chat-comet-action-bubble',{key:JSON.stringify(message),attrs:{"theme":_vm.theme,"message":message,"logged-in-user":_vm.loggedInUser}})]:_vm._e(),(message.category === 'message')?[(_vm.loggedInUser.uid === message.sender.uid)?[(_vm.hasProperty(message, 'deletedAt'))?[_c('div',{staticClass:"text-sm lb-mb-4 text-left"},[_vm._v("Delete message")])]:[(
                message.type === _vm.COMET_CHAT.MESSAGE_TYPE.TEXT && message.text
              )?[_c('chat-comet-sender-text-message',{key:JSON.stringify(message),attrs:{"message":message}})]:(
                message.type === _vm.COMET_CHAT.MESSAGE_TYPE.IMAGE &&
                message.data.url
              )?[_c('chat-comet-sender-image-message',{key:JSON.stringify(message),attrs:{"message":message}})]:(
                message.type === _vm.COMET_CHAT.MESSAGE_TYPE.FILE &&
                message.data.attachments
              )?[_c('chat-comet-sender-file-message',{key:JSON.stringify(message),attrs:{"message":message}})]:_vm._e()]]:[(_vm.hasProperty(message, 'deletedAt'))?[_c('div',{staticClass:"text-sm lb-mb-4 text-left"},[_vm._v("Delete message")])]:[(
                message.type === _vm.COMET_CHAT.MESSAGE_TYPE.TEXT && message.text
              )?[_c('chat-comet-text-message',{key:JSON.stringify(message),attrs:{"message":message}})]:(
                message.type === _vm.COMET_CHAT.MESSAGE_TYPE.IMAGE &&
                message.data.url
              )?[_c('chat-comet-image-message',{key:JSON.stringify(message),attrs:{"message":message}})]:(
                message.type === _vm.COMET_CHAT.MESSAGE_TYPE.FILE &&
                message.data.attachments
              )?[_c('chat-comet-file-message',{key:JSON.stringify(message),attrs:{"message":message}})]:_vm._e()]]]:(message.category === 'action' && _vm.canShowMessage(message))?[_c('div',{key:JSON.stringify(message)},[_c('div',{staticClass:"text-md text-center lb-tp-15"},[_vm._v(_vm._s(message.message))])])]:_vm._e()]})],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"loader-wrapper lb-w-chat-loader"},[_c('div',{staticClass:"loader-circle md"},[_c('img',{attrs:{"src":require("@/assets/img/loader-icon.png"),"alt":"loader"}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"loader-wrapper lb-w-chat-loader"},[_c('div',{staticClass:"loader-circle md"},[_c('img',{attrs:{"src":require("@/assets/img/loader-icon.png"),"alt":"loader"}})])])}]

export { render, staticRenderFns }