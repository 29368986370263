<template>
  <div>
    <div>
      <ul class="tabs-nav type-two lb-mb-15">
        <li @click="material_filter.type = 'course'" :class="{'is-active': material_filter.type === 'course'}"><span>Courses</span><span v-if="!quantity_loading" class="badge-sm">{{ob_get_key('course')}}</span></li>
        <!--
        <li @click="material_filter.type = 'book'" :class="{'is-active': material_filter.type === 'book'}"><span>Books</span><span v-if="!quantity_loading" class="badge-sm">{{ob_get_key('book')}}</span></li>
        <li @click="material_filter.type = 'article'" :class="{'is-active': material_filter.type === 'article'}"><span>Articles</span><span v-if="!quantity_loading" class="badge-sm">{{ob_get_key('article')}}</span></li>
        -->
        <li @click="material_filter.type = 'my_learning'" :class="{'is-active': material_filter.type === 'my_learning'}"><span>User learning</span><span v-if="!quantity_loading" class="badge-sm">{{ob_get_key('my_learning')}}</span></li>
      </ul>
      <div class="lb-mb-15">
        <div class="row justify-content-between align-items-center">
          <div class="col-auto">
            <div class="row row-8 align-items-center">
              <div class="col-auto">
                Filter
              </div>
              <div class="col-auto">
                <div class="dropdown checkbox">
                  <span class="dropdown-overlay" @click="dropClose"></span>
                  <button class="btn btn-outlined" @click="dropOpen">Rating</button>
                  <ul class="dropdown-inner">
                    <li><label class="checkbox-entry"><input type="checkbox" value="1" class="subscribe-input" v-model="material_filter.rating"><span>1 Star</span></label></li>
                    <li><label class="checkbox-entry"><input type="checkbox" value="2" class="subscribe-input" v-model="material_filter.rating"><span>2 Stars</span></label></li>
                    <li><label class="checkbox-entry"><input type="checkbox" value="3" class="subscribe-input" v-model="material_filter.rating"><span>3 Stars</span></label></li>
                    <li><label class="checkbox-entry"><input type="checkbox" value="4" class="subscribe-input" v-model="material_filter.rating"><span>4 Stars</span></label></li>
                    <li><label class="checkbox-entry"><input type="checkbox" value="5" class="subscribe-input" v-model="material_filter.rating"><span>5 Stars</span></label></li>
                  </ul>
                </div>
              </div>
              <div class="col-auto">
                <div class="dropdown checkbox type-1">
                  <span class="dropdown-overlay" @click="dropClose"></span>
                  <button class="btn btn-outlined" @click="dropOpen">Topic</button>
                  <div class="dropdown-inner">
                    <div class="input-wrapper-icon wh-incon lb-mb-8">
                      <input
                        placeholder="Search"
                        class="input sm"
                        type="text"
                        v-model="tegs_search"
                      >
                    </div>
                    <div v-if="tegs_loading" class="loader-wrapper p-0">
                      <div class="loader-circle sm">
                        <img src="@/assets/img/loader-icon.png" alt="loader">
                      </div>
                    </div>
                    <ul class="in_mx_scroll">
                      <li
                        v-for="(item, i) in tegs_filter"
                        :key="i + '39'"
                      >
                        <label class="checkbox-entry" :title="item.skill"><input type="checkbox" :value="item.id" class="subscribe-input" v-model="material_filter.tags">
                          <span>{{item.skill.substring(0,24)}} <i v-if="item.skill.length > 24">...</i></span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <div class="dropdown checkbox">
                  <span class="dropdown-overlay" @click="dropClose"></span>
                  <button class="btn btn-outlined" @click="dropOpen">Level</button>
                  <ul class="dropdown-inner">
                    <li><label class="checkbox-entry"><input type="checkbox" :value="1" class="subscribe-input" v-model="material_filter.level"><span>1</span></label></li>
                    <li><label class="checkbox-entry"><input type="checkbox" :value="2" class="subscribe-input" v-model="material_filter.level"><span>2</span></label></li>
                    <li><label class="checkbox-entry"><input type="checkbox" :value="3" class="subscribe-input" v-model="material_filter.level"><span>3</span></label></li>
                    <li><label class="checkbox-entry"><input type="checkbox" :value="4" class="subscribe-input" v-model="material_filter.level"><span>4</span></label></li>
                    <li><label class="checkbox-entry"><input type="checkbox" :value="5" class="subscribe-input" v-model="material_filter.level"><span>5</span></label></li>
                  </ul>
                </div>
              </div>
              <div class="col-auto">
                <div class="dropdown checkbox">
                  <span class="dropdown-overlay" @click="dropClose"></span>
                  <button class="btn btn-outlined" @click="dropOpen">Platform</button>
                  <ul class="dropdown-inner">
                    <li><label class="checkbox-entry"><input type="checkbox" value="Coursera" class="subscribe-input" v-model="material_filter.platform"><span>Coursera</span></label></li>
                    <li><label class="checkbox-entry"><input type="checkbox" value="Udemy" class="subscribe-input" v-model="material_filter.platform"><span>Udemy</span></label></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-auto">
            <div class="row row-8 align-items-center">
              <div class="col-auto">
                <div class="input-wrapper-icon clean">
                  <span class="icon"><img src="@/assets/img/icons/icon-search-grey.svg" alt="icon-search"></span>
                  <button
                    class="icon-clean"
                    v-if="search_input.length"
                    @click="search_input = ''; search_mt()"
                  >
                    <img src="@/assets/img/icons/icon-clear.svg" alt="icon-search">
                  </button>
                  <input
                    class="input sm"
                    placeholder="Search"
                    type="text"
                    v-model="search_input"
                    @keypress.enter="search_mt()"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!material_loading">
        <div v-if="materials.length > 0">
          <div class="card-wrapper-negative">
            <div class="row row-32 card-wrap">
              <div v-for="(post, i) in materials" :key="i" class="col-lg-4">
                <div
                  class="card"
                  @click="openMoreInfo(post.id)"
                >
                  <div class="card-image">
                    <img v-if="post.image" :src="post.image" alt="image">
                    <img v-else src="@/assets/img/image-placeholder.jpg" alt="image">
                  </div>
                  <div class="lb-mb-8">
                    <div class="card-info">
                      <div class="tags">
                        <div
                          class="badge-dark-wrap"
                          v-if="post.tags.length > 0"
                        >
                          <span class="badge-dark" v-for="(item, a) in post.tags" :key="a + 's'">{{item.skill}}</span>
                        </div>
                      </div>
                      <div v-if="post.source" class="platforms">
                        <span class="color-grey-2 text-sm">{{post.source}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="h4 lb-mb-8"><span class="max-l-a">{{post.title}}</span></div>
                  <div class="text-md lb-mb-8"><span class="color-grey">Author: </span><span class="color-dark">{{post.author}}</span></div>
                  <div class="card-rating text-md">
                    <span v-if="post.rating" class="icon">
                      <img v-if="post.rating < 3.5" src="@/assets/img/icons/icon-star-2.svg" alt="icon">
                      <img v-else src="@/assets/img/icons/icon-star.svg" alt="icon">
                    </span>
                    <span v-if="post.rating" class="color-dark">{{post.rating}}</span>
                    <span v-if="post.reviwes" class="color-grey"> ({{post.reviwes}} reviews)</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center lb-bp-32">
            <div class="lb-mb-20">
              <button
                class="btn btn-outlined btn-loader"
                @click="show_more"
                :class="{'disable': materials_pages.last_page <= materials_pages.current_page || materials_pages.last_page <= sh_last_active, 'loader': show_more_loading}"
              >
                More
              </button>
            </div>
            <div class="pagination">
              <button
                class="btn btn-outlined prev"
                :class="{'disable': 1 >= materials_pages.current_page}"
                @click="material_filter.current_page -= 1"
              >
                Prev
              </button>
              <ul>
                <li
                  class="btn btn-outlined"
                  v-if="materials_pages.current_page !== 1"
                  @click="material_filter.current_page = 1"
                >
                  1
                </li>
                <li
                  v-if="materials_pages.current_page > 2"
                >...</li>
                <li
                  class="btn btn-outlined is-active"
                >
                  {{materials_pages.current_page}}
                </li>
                <li
                  v-if="materials_pages.current_page < (materials_pages.last_page - 1)"
                >...</li>
                <li
                  class="btn btn-outlined"
                  v-if="materials_pages.current_page !== materials_pages.last_page"
                  @click="material_filter.current_page = materials_pages.last_page"
                >
                  {{materials_pages.last_page}}
                </li>
              </ul>
              <button
                class="btn btn-outlined next"
                :class="{'disable': materials_pages.last_page <= materials_pages.current_page}"
                @click="material_filter.current_page += 1"
              >
                Next
              </button>
            </div>
          </div>
          <!--
          <div class="text-center lb-bp-32">
            <div class="lb-mb-20">
              <button
                class="btn btn-outlined btn-loader"
                @click="show_more"
                :class="{'disable': materials_pages.last_page <= materials_pages.current_page || materials_pages.last_page <= sh_last_active, 'loader': show_more_loading}"
              >
                More
              </button>
            </div>
            <div class="pagination">
              <button
                class="btn btn-outlined prev"
                :class="{'disable': 1 >= materials_pages.current_page}"
                @click="material_filter.current_page -= 1"
              >
                Prev
              </button>
              <ul>
                <li
                  class="btn btn-outlined"
                  :class="{'is-active': materials_pages.current_page === item || item <= sh_last_active && sh_last_active > materials_pages.current_page}"
                  v-for="(item, i) in materials_pages.last_page"
                  :key="i + 's'"
                  @click="material_filter.current_page = item"
                >
                  {{item}}
                </li>
              </ul>
              <button
                class="btn btn-outlined next"
                :class="{'disable': materials_pages.last_page <= materials_pages.current_page || materials_pages.last_page <= sh_last_active}"
                @click="material_filter.current_page += 1"
              >
                Next
              </button>
            </div>
          </div>
          -->
        </div>
        <div v-else>None</div>
      </div>
      <div v-else class="loader-wrapper">
        <div class="loader-circle">
          <img src="@/assets/img/loader-icon.png" alt="loader">
        </div>
      </div>
    </div>
    <pop-up
      :show-modal="showModal"
      slide-animation="slideleft"
      view-modal-container="modal-container-two type-four"
      @closemodal="closeMoreInfo"
    >
      <div v-if="!material_one_loading">
        <div v-if="material_one">
          <div class="modal-header lb-mb-15">
            <div class="h2 color-dark lb-mb-8">{{material_one.title}}</div>
            <div v-if="material_one.description" class="color-grey">{{material_one.description}}</div>
          </div>
          <div class="lb-mb-24">
            <div class="row row-16">
              <div class="col-auto"><span class="badge-ml bg-orang text-sm">Sales leader</span></div>
              <div class="col-auto">
                <div class="card-rating text-md">
                  <span v-if="material_one.rating" class="icon">
                    <img src="@/assets/img/icons/icon-star.svg" alt="icon">
                  </span>
                  <span class="color-dark">{{material_one.rating}}</span>
                  <span v-if="material_one.reviwes" class="color-grey"> ({{material_one.reviwes}} reviews)</span>
                </div>
              </div>
            </div>
          </div>
          <div class="btn-wrapper lb-mb-15">
            <button
              class="btn btn-primary btn-loader"
              :class="{'loader': add_list_loading}"
              @click="addInLearningList(material_one.id)"
            >
              Add to Clients learning list
            </button>
            <!--
            <button class="btn btn-secondary">Share</button>
            -->
          </div>
          <div>
            <ul class="tabs-nav">
              <li @click.prevent="setActive('tab-1')" :class="{ 'is-active': isActive('tab-1') }">Overview</li>
              <!--
              <li @click.prevent="setActive('tab-2')" :class="{ 'is-active': isActive('tab-2') }">Materials</li>
              -->
              <li @click.prevent="setActive('tab-3')" :class="{ 'is-active': isActive('tab-3') }">Requirements</li>
              <!--
              <li @click.prevent="setActive('tab-4')" :class="{ 'is-active': isActive('tab-4') }">Reviews</li>
              -->
            </ul>
            <div class="row row-32 nowrap">
              <div class="col-w-100">
                <div>
                  <div class="tabs-container lb-tp-25" :class="{ 'is-active': isActive('tab-1') }">
                    <div class="h3 lb-mb-15">About course</div>
                    <div class="custom-grid text-md lb-mb-32">
                      <div v-if="material_one.author" class="custom-grid-item">
                        <div class="label">Author:</div>
                        <div class="content color-dark">{{material_one.author}}</div>
                      </div>
                      <div v-if="material_one.language" class="custom-grid-item">
                        <div class="label">Language:</div>
                        <div class="content color-dark">{{material_one.language}}</div>
                      </div>
                      <!--
                      <div v-if="material_one.subtitles && JSON.parse(material_one.subtitles).length > 0" class="custom-grid-item">
                        <div class="label">Subtitles:</div>
                        <div class="content color-dark">
                            <span
                              v-for="(item, i) in JSON.parse(material_one.subtitles)"
                              :key="i + 'st'"
                            >{{item}}<span v-if="JSON.parse(material_one.subtitles).length !== (i + 1)">,</span> </span>
                        </div>
                      </div>
                      -->
                      <!--
                      <div class="custom-grid-item">
                        <div class="label">Access:</div>
                        <div class="content color-dark">Full access</div>
                      </div>
                      <div class="custom-grid-item">
                        <div class="label">Students:</div>
                        <div class="content color-dark">475 282</div>
                      </div>
                      -->
                      <div v-if="material_one.certificate" class="custom-grid-item">
                        <div class="label">Certificate:</div>
                        <div class="content color-dark">{{material_one.certificate}}</div>
                      </div>
                    </div>
                    <!--
                    <div class="h3 lb-mb-15">What will you learn</div>
                    <div class="text text-md color-dark">
                      <ul>
                        <li>Become an advanced, confident, and modern JavaScript developer from scratch </li>
                        <li>Build 6 beautiful real-world projects for your portfolio (not boring toy apps)</li>
                        <li>Become job-ready by understanding how JavaScript really works behind the scenes</li>
                      </ul>
                    </div>
                    -->
                  </div>
                  <!--
                  <div class="tabs-container lb-tp-25" :class="{ 'is-active': isActive('tab-2') }">
                    <div class="h3 lb-mb-4">Course materials</div>
                    <div class="color-grey lb-mb-15">21 sections • 314 lectures • 68h 21m total length</div>
                    <div>
                      <accordion-slide>
                        <template v-slot:header>
                          <div class="accordion-slide-hd-inner">
                            <span class="h4 title">Welcome, Welcome, Welcome</span>
                            <span class="text-md info">5 lectures • 23 min</span>
                          </div>
                        </template>
                        <template v-slot:content>
                          <div class="accordion-content-inner">
                            <div class="list-media-wrapper text-md color-dark">
                              <div class="list-media">
                                <span class="icon"><img src="@/assets/img/icons/icon-play_circle_filled.svg" alt="icon"></span>
                                <div class="title">Course Structures and Projects</div>
                                <div class="info">05:25</div>
                              </div>
                              <div class="list-media">
                                <span class="icon"><img src="@/assets/img/icons/icon-play_circle_filled.svg" alt="icon"></span>
                                <div class="title">Watch Before You Start!</div>
                                <div class="info">06:09</div>
                              </div>
                              <div class="list-media">
                                <span class="icon"><img src="@/assets/img/icons/icon-article-093.svg" alt="icon"></span>
                                <div class="title">Read Before You Start!</div>
                                <div class="info">00:41</div>
                              </div>
                            </div>
                          </div>
                        </template>
                      </accordion-slide>
                      <accordion-slide>
                        <template v-slot:header>
                          <div class="accordion-slide-hd-inner">
                            <span class="h4 title">JavaScript Fundamentals – Part 1</span>
                            <span class="text-md info">19 lectures • 10 hr 2 min</span>
                          </div>
                        </template>
                        <template v-slot:content>
                          <div class="accordion-content-inner">
                            <div class="list-media-wrapper text-md color-dark">
                              <div class="list-media">
                                <span class="icon"><img src="@/assets/img/icons/icon-play_circle_filled.svg" alt="icon"></span>
                                <div class="title">Course Structures and Projects</div>
                                <div class="info">05:25</div>
                              </div>
                              <div class="list-media">
                                <span class="icon"><img src="@/assets/img/icons/icon-play_circle_filled.svg" alt="icon"></span>
                                <div class="title">Watch Before You Start!</div>
                                <div class="info">06:09</div>
                              </div>
                              <div class="list-media">
                                <span class="icon"><img src="@/assets/img/icons/icon-article-093.svg" alt="icon"></span>
                                <div class="title">Read Before You Start!</div>
                                <div class="info">00:41</div>
                              </div>
                            </div>
                          </div>
                        </template>
                      </accordion-slide>
                    </div>
                  </div>
                  -->
                  <div class="tabs-container lb-tp-25" :class="{ 'is-active': isActive('tab-3') }">
                    <div class="h3 lb-mb-15">Requirements</div>
                    <div v-if="material_one.requirements" class="text text-md">
                      <span v-html="material_one.requirements"></span>
                    </div>
                    <span v-else>None</span>
                  </div>
                  <!--
                  <div class="tabs-container lb-tp-25" :class="{ 'is-active': isActive('tab-4') }">
                    <div class="reviews-wrapper">
                      <div class="reviews-item">
                        <div class="reviews-header">
                          <div class="image">
                            <img src="@/assets/img/default-user-avatar.png" alt="image">
                          </div>
                          <div class="h4 name">vikram n.c.</div>
                          <div class="text-sm color-grey-2 time">a month ago</div>
                          <div class="rating">
                        <span v-for="(e, i) in 5" :key="i + 's'" class="icon">
                          <img v-if="(i + 0.4) <= reting && (i + 1) > reting " src="@/assets/img/icons/icon-star-orange-05.svg" alt="icon">
                          <img v-else-if="(i + 1) <= reting" src="@/assets/img/icons/icon-star-orange.svg" alt="icon">
                          <img v-else src="@/assets/img/icons/icon-star-grey.svg" alt="icon">
                        </span>
                          </div>
                        </div>
                        <div class="reviews-text text-md color-grey">
                          Hands down, this is simply the best JavaScript course out there if someone wants to learn JavaScript, not only to code but also to know how it actually works behind. Jonas sir, I simply have no words to describe your teaching, You are one of the best teacher I've met in my life, explained everything in a great detail, lot of times in the course, especially in forkify section my mind was blown away by seeing the knowledge that you have and the way you brought every puzzle pieces together when implementing the MVC architecture, the slides helped a lot to revise the concepts. Thank you so much for sharing your knowledge with us and I will see you soon in your node course for sure. Thanks a lot for this course
                        </div>
                      </div>
                      <div class="reviews-item">
                        <div class="reviews-header">
                          <div class="image">
                            <img src="@/assets/img/default-user-avatar.png" alt="image">
                          </div>
                          <div class="h4 name">Ben Mather</div>
                          <div class="text-sm color-grey-2 time">a 2 months ago</div>
                          <div class="rating">
                        <span v-for="(e, i) in 5" :key="i + 's'" class="icon">
                          <img v-if="(i + 0.4) <= reting && (i + 1) > reting " src="@/assets/img/icons/icon-star-orange-05.svg" alt="icon">
                          <img v-else-if="(i + 1) <= reting" src="@/assets/img/icons/icon-star-orange.svg" alt="icon">
                          <img v-else src="@/assets/img/icons/icon-star-grey.svg" alt="icon">
                        </span>
                          </div>
                        </div>
                        <div class="reviews-text text-md color-grey">
                          I come from a C/C++ background. It's taken me over 3 months to complete this course - a little every day and taken lots of notes to refer back to. I've found it very worthwhile and no doubt will give me a solid foundation for any future JS coding I may do. Time very well spent during lockdown! I appreciate the time you've invested in this Jonas.
                        </div>
                      </div>
                    </div>
                  </div>
                  -->
                </div>
              </div>
              <div v-if="!isActive('tab-4')" class="col-auto">
                <div class="lb-tp-25">
                  <div class="thumbnail-video">
                    <div v-if="material_one.image" class="bg" :style="{'background-image': 'url(' + material_one.image + ')'}"></div>
                    <div v-else class="bg" :style="{'background-image': 'url(' + require('@/assets/img/image-placeholder.jpg') + ')'}"></div>
                    <span class="icon"><img src="@/assets/img/icons/icon-play_circle.svg" alt="icon"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="loader-wrapper">
        <div class="loader-circle">
          <img src="@/assets/img/loader-icon.png" alt="loader">
        </div>
      </div>
    </pop-up>
  </div>
</template>

<script>
import PopUp from '@/components/PopUp'
// import AccordionSlide from '@/components/AccordionSlideBG'
export default {
  name: 'TeachingMaterial',
  data () {
    return {
      reting: 3.5,
      activeItem: 'tab-1',
      showModal: false,
      search_input: '',
      material_filter: {
        type: 'course',
        platform: [],
        level: [],
        rating: [],
        tags: [],
        order_sort: 'reviwes',
        search: '',
        current_page: 1
      },
      material_sort: [
        {
          key: 'reviwes',
          title: 'Most popular'
        },
        {
          key: 'new',
          title: 'New'
        },
        {
          key: 'rating',
          title: 'Rating'
        },
        {
          key: 'title',
          title: 'Name'
        }
      ],
      material_loading: true,
      material_one_loading: true,
      tegs_default: [],
      tegs_other: [],
      tegs_search: '',
      tegs_loading: false,
      quantity_loading: true,
      show_more_loading: false,
      sh_last_active: 1,
      add_list_loading: false
    }
  },
  computed: {
    tegs_filter: function () {
      const d = [...this.tegs_default, ...this.tegs_other]
      return d.filter(v => {
        if (this.tegs_search === '') {
          return true
        } else {
          return v.skill.toLowerCase().includes(this.tegs_search.toLowerCase())
        }
      })
    },
    materials_pages: function () {
      return this.$store.getters.materials
    },
    materials: function () {
      return this.$store.getters.materials.data
    },
    material_one: function () {
      return this.$store.getters.material_one
    },
    materials_quantity: function () {
      return this.$store.getters.materials_quantity
    },
    active_user_id () {
      return this.$store.getters.activeUser
    },
    userSecurity () {
      return this.$store.getters.userSecurity
    }
  },
  methods: {
    ob_get_key: function (a) {
      const v = JSON.parse(JSON.stringify(this.materials_quantity))
      const c = v.find(e => Object.keys(e)[0] === a)
      return Object.entries(c)[0][1]
    },
    dropOpen: function (e) {
      e.target.closest('.dropdown').classList.add('is-active')
    },
    dropClose: function (e) {
      e.target.closest('.dropdown').classList.remove('is-active')
    },
    isActive (e) {
      return this.activeItem === e
    },
    setActive (menuItem) {
      this.activeItem = menuItem
    },
    openMoreInfo: function (id) {
      this.material_one_loading = true
      this.showModal = true
      this.$utils.blockScroll()
      this.$store.dispatch('fetchMaterialsOne', id)
        .finally(() => {
          this.material_one_loading = false
        })
    },
    closeMoreInfo: function () {
      this.showModal = false
      this.$utils.unBlockScroll()
    },
    search_mt: function () {
      this.material_filter.search = this.search_input
    },
    fetch_materials: function (e) {
      this.material_loading = true
      this.material_filter.client_id = this.active_user_id
      this.sh_last_active = e.current_page
      this.$store.dispatch('fetchMaterials', e)
        .finally(() => {
          if (this.material_filter.current_page !== 1 && this.materials.length < 1) {
            this.material_filter.current_page = 1
            this.$store.dispatch('fetchMaterials', this.material_filter)
              .finally(() => {
                this.material_loading = false
              })
          } else {
            this.material_loading = false
          }
        })
    },
    quantityMaterials: function (e) {
      console.log('start qu')
      this.$store.dispatch('quantityMaterials', e)
        .finally(() => {
          this.quantity_loading = false
        })
    },
    addInLearningList: function (i) {
      const p = {
        material_id: i,
        client_id: this.active_user_id
      }
      this.add_list_loading = true
      this.$store.dispatch('addInLearningList', p)
        .finally(() => {
          this.add_list_loading = false
        })
    },
    show_more: function () {
      this.show_more_loading = true
      const d = JSON.parse(JSON.stringify(this.material_filter))
      d.current_page = this.sh_last_active + 1
      this.sh_last_active = d.current_page
      console.log(d)
      this.$store.dispatch('fetchShowMore', d)
        .finally(() => {
          this.show_more_loading = false
        })
    },
    fetch_teg: function () {
      this.$axios({
        method: 'get',
        url: this.$root.$data.baseURL + 'apicall/client/getmaterialtags'
      })
        .then((r) => {
          this.tegs_default = [...r.data]
        })
        .catch(function (e) {
          console.log(e.response)
        })
    }
  },
  watch: {
    material_filter: {
      handler: function (v) {
        this.fetch_materials(v)
        this.quantityMaterials(v)
      },
      deep: true
    },
    active_user_id: function (v) {
      this.fetch_materials(this.material_filter)
      this.quantityMaterials(this.material_filter)
    },
    tegs_search: function (v) {
      if (v.length >= 2) {
        this.tegs_loading = true
        this.$axios({
          method: 'post',
          headers: {
            Authorization: 'Bearer ' + this.userSecurity.token,
            'X-Api-Key': this.userSecurity.key
          },
          url: this.$root.$data.baseURL + 'apicall/coach/tagsearch',
          params: {
            search: v
          }
        })
          .then((r) => {
            this.tegs_other = [...r.data]
            this.tegs_loading = false
          })
          .catch(function (e) {
            console.log(e.response)
            this.tegs_loading = false
            console.log(e)
          })
      } else {
        this.tegs_other = []
        this.tegs_loading = false
      }
    }
  },
  mounted () {
    this.fetch_teg()
    this.fetch_materials(this.material_filter)
    this.quantityMaterials(this.material_filter)
  },
  components: {
    PopUp
    // AccordionSlide
  }
}
</script>

<style scoped lang="scss">
@import "../assets/scss/_variables.scss";
@import "../assets/scss/_header.scss";
.card-wrapper-negative{
  margin-left: -8px;
  margin-right: -8px;
}
.pagination{
  display: inline-block;
  padding: 0 65px;
  position: relative;
  .next,
  .prev{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .next{
    right: 0;
  }
  .prev{
    left: 0;
  }
  ul{
    display: flex;
    align-items: center;
  }
  li{
    margin-right: 5px;
    &:last-child{
      margin-right: 0;
    }
    &.is-active{
      pointer-events: none;
    }
  }
}
.thumbnail-video{
  padding-bottom: 100%;
  width: 300px;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  .bg{
    background-position: center;
  }
  .icon{
    width: 64px;
    line-height: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    img{
      max-width: 100%;
    }
  }
}
.reviews{
  &-item{
    padding: 24px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    margin-bottom: 8px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  &-header{
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    .image{
      width: 40px;
      height: 40px;
      flex: 0 0 40px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 12px;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .name{
      margin-right: 12px;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      max-width: 60%;
    }
    .time{
      margin-left: auto;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .rating{
      display: flex;
      align-items: center;
      margin-left: 12px;
      .icon{
        display: inline-block;
        line-height: 0;
        width: 24px;
        img{
          max-width: 100%;
        }
      }
    }
  }
}
.list-media{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0 5px 32px;
  position: relative;
  margin-bottom: 6px;
  &:last-child{
    margin-bottom: 0;
  }
  .title{
    margin-right: 16px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .info{
    white-space: nowrap;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    flex: 1 0 100px;
    text-align: right;
  }
  .icon{
    display: inline-block;
    width: 24px;
    line-height: 0;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -12px;
    img{
      width: 100%;
    }
  }
}
.custom-grid{
  &-item{
    display: flex;
    margin-bottom: 8px;
    &:last-child{
      margin-bottom: 0;
    }
    .label{
      min-width: 160px;
      padding-right: 16px;
    }
  }
}
.accordion-slide-hd-inner{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .title{
    margin-right: 16px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .info{
    white-space: nowrap;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    flex: 1 0 180px;
    text-align: right;
  }
}
.accordion-content-inner{
  padding: 16px;
}
</style>
