<template>
  <div>
    <ul class="tabs-nav type-two lb-mb-15">
      <li @click.prevent="setActive('tab-1')" :class="{ 'is-active': isActive('tab-1') }"><span>Chat</span></li>
      <li @click.prevent="setActive('tab-2')" :class="{ 'is-active': isActive('tab-2') }"><span>Development plan</span></li>
      <li @click.prevent="setActive('tab-3')" :class="{ 'is-active': isActive('tab-3') }"><span>Goals</span></li>
      <li @click.prevent="setActive('tab-4')" :class="{ 'is-active': isActive('tab-4') }"><span>Report</span></li>
      <li @click.prevent="setActive('tab-5')" :class="{ 'is-active': isActive('tab-5') }"><span>Knowledge base</span></li>
      <li @click.prevent="setActive('tab-6')" :class="{ 'is-active': isActive('tab-6') }"><span>Payment history</span></li>
      <li @click.prevent="setActive('tab-7')" :class="{ 'is-active': isActive('tab-7') }"><span>Notes</span></li>
    </ul>
    <div>
      <div class="tabs-container" v-if="isActive('tab-1')" :class="{ 'is-active': isActive('tab-1') }">
        <chat-comet
          :item="item"
          :type="type"
          @action="actionHandler"
        ></chat-comet>
      </div>
      <div class="tabs-container" v-if="isActive('tab-2')" :class="{ 'is-active': isActive('tab-2') }">
        <block-layout>
          <timeline-plan-dev v-if="is_timeline"></timeline-plan-dev>
        </block-layout>
      </div>
      <div class="tabs-container" v-if="isActive('tab-3')" :class="{ 'is-active': isActive('tab-3') }">
        <block-layout>
          <workspace-goals></workspace-goals>
        </block-layout>
      </div>
      <div class="tabs-container" v-if="isActive('tab-4')" :class="{ 'is-active': isActive('tab-4') }">
        <block-layout>
          <div class="lb-mb-20">
            <div class="row justify-content-between align-items-center">
              <div class="col-auto">
                <div class="h3 color-dark">Report</div>
              </div>
              <div class="col-auto">
                <div class="btn-wrapper">
                  <!--
                  <button class="btn btn-ghost">Update Report</button>
                  -->
                  <button
                    class="btn btn-outlined"
                    :class="{'loader': loading_report}"
                    @click="show_report"
                  >
                    Download Report
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!--
          <div class="row">
            <div class="col-lg-6">
              <div class="h4 lb-mb-8">Hard skills</div>
              <div class="color-grey text-md">Skills acquired in educational<br> programmes and professional activities.</div>
              <div class="chart-wrapper">
                <radar-chart
                  :data="radarData"
                  :options="radarOptions"
                ></radar-chart>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="h4 lb-mb-8">Soft skills</div>
              <div class="color-grey text-md">Personal qualities that characterize your<br> communication and work interaction.</div>
              <div class="chart-wrapper">
                <radar-chart
                  :data="radarDataTwo"
                  :options="radarOptionsTwo"
                ></radar-chart>
              </div>
            </div>
          </div>
          -->
        </block-layout>
      </div>
      <div class="tabs-container" v-if="isActive('tab-5')" :class="{ 'is-active': isActive('tab-5') }">
        <block-layout>
          <div class="h3 lb-mb-20">Knowledge base</div>
          <teaching-material></teaching-material>
        </block-layout>
      </div>
      <div class="tabs-container" v-if="isActive('tab-6')"  :class="{ 'is-active': isActive('tab-6') }">
        <block-layout>
          <table-payment-history></table-payment-history>
        </block-layout>
      </div>
      <div class="tabs-container" v-if="isActive('tab-7')"  :class="{ 'is-active': isActive('tab-7') }">
        <block-layout>
          <editor-notes></editor-notes>
        </block-layout>
      </div>
    </div>
  </div>
</template>

<script>
import EditorNotes from '@/components/EditorNotes.vue'
import TeachingMaterial from '@/components/TeachingMaterial'
// import RadarChart from '@/components/RadarChart'
import WorkspaceGoals from '@/components/WorkspaceGoals'
import TimelinePlanDev from '@/components/TimelinePlanDev'
import BlockLayout from '@/components/BlockLayout'
import TablePaymentHistory from '@/components/TablePaymentHistory'
import ChatComet from '@/components/ChatComet'
import { cometChatCommon } from '../mixins/'
export default {
  mixins: [cometChatCommon],
  props: {
    activeUserChat: {
      default: ''
    },
    item: {
      type: Object
    },
    type: {
      type: String
    }
  },
  data () {
    return {
      is_timeline: true,
      radarData: {
        labels: ['Analytical thinking', 'Leadership', 'Persistance', 'Cooperation', 'Logical thinking', 'Reliability', 'Attention to detail'],
        datasets: [
          {
            backgroundColor: 'rgba(130, 106, 249, 0.7)',
            borderColor: 'rgba(130, 106, 249, 0.7)',
            tension: 0.5,
            data: [65, 59, 90, 81, 56, 55, 40],
            radius: 0
          },
          {
            pointBackgroundColor: '#826AF9',
            pointBorderColor: '#826AF9',
            pointBorderWidth: 2,
            backgroundColor: 'rgba(1,1,1,0)',
            tension: 0,
            data: [100, 100, 100, 100, 100, 100, 100, 100],
            borderWidth: 0,
            borderColor: 'rgba(1,1,1,0)'
          }
        ]
      },
      radarOptions: {
        datasets: {
          bar: {
            barPercentage: 0.5,
            categoryPercentage: 0.4
          },
          horizontalBar: {
            barPercentage: 0.5,
            categoryPercentage: 0.4
          }
        },
        defaultFontColor: 'red',
        hover: { mode: null },
        animation: false,
        plugins: {
          title: {
            display: false
          }
        },
        scale: {
          gridLines: {
            display: true,
            color: '#E9EBF1',
            circular: true,
            tickMarkLength: 5
          },
          ticks: {
            display: false,
            mirror: true,
            beginAtZero: true,
            backdropColor: 'red',
            maxTicksLimit: 6
          },
          angleLines: {
            display: true,
            // lineWidth: 4,
            color: '#E9EBF1'
          },
          pointLabels: {
            fontSize: 14
          }
        },
        title: {
          // display: true
        },
        tooltips: false,
        legend: false
      },
      radarDataTwo: {
        labels: ['Analytical thinking', 'Leadership', 'Persistance', 'Cooperation', 'Logical thinking', 'Reliability', 'Attention to detail'],
        datasets: [
          {
            backgroundColor: '#79BFFF',
            borderColor: '#79BFFF',
            tension: 0.5,
            data: [65, 59, 90, 81, 56, 55, 40],
            radius: 0
          },
          {
            pointBackgroundColor: '#79BFFF',
            pointBorderColor: '#79BFFF',
            pointBorderWidth: 2,
            backgroundColor: 'rgba(1,1,1,0)',
            tension: 0,
            data: [100, 100, 100, 100, 100, 100, 100, 100],
            borderWidth: 0,
            borderColor: 'rgba(1,1,1,0)'
          }
        ]
      },
      radarOptionsTwo: {
        hover: { mode: null },
        animation: false,
        plugins: {
          title: {
            display: false
          }
        },
        scale: {
          gridLines: {
            display: true,
            color: '#E9EBF1',
            circular: true
          },
          ticks: {
            display: false,
            mirror: true,
            beginAtZero: true,
            maxTicksLimit: 6
          },
          angleLines: {
            display: true,
            // lineWidth: 4,
            color: '#E9EBF1'
          },
          pointLabels: {
            fontSize: 14
          },
          scaleLabel: {
            display: true,
            labelString: '9'
          }
        },
        title: {
          // display: true
        },
        tooltips: false,
        legend: false
      },
      loading_report: false
    }
  },
  computed: {
    activeItem: function () {
      return this.$store.getters.tab_active
    },
    active_user_id () {
      return this.$store.getters.activeUser
    },
    userSecurity () {
      return this.$store.getters.userSecurity
    }
  },
  methods: {
    actionHandler ({ action, item, type }) {
      switch (action) {
        case 'item-click':
          this.emitAction(action, { item, type })
          break
        default:
          break
      }
    },
    isActive (e) {
      return this.activeItem === e
    },
    setActive (item) {
      this.$store.commit('change_tab_active', item)
    },
    show_report: function () {
      this.loading_report = true
      this.$axios({
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + this.userSecurity.token,
          'X-Api-Key': this.userSecurity.key
        },
        url: this.$root.$data.baseURL + 'apicall/coach/getreport',
        params: {
          id: this.active_user_id
        }
      })
        .then((r) => {
          this.loading_report = false
          window.open(r.data.report, '_blank').focus()
        })
        .catch(function (e) {
          this.loading_report = false
          console.log(e)
        })
    }
  },
  mounted () {
    this.$utils.scrollHG()
    window.addEventListener('resize', this.$utils.scrollHG)
  },
  watch: {
    active_user_id: function (v) {
      this.is_timeline = false
      setTimeout(() => {
        this.is_timeline = true
      }, 50)
    }
  },
  components: {
    EditorNotes,
    TeachingMaterial,
    // RadarChart,
    WorkspaceGoals,
    TimelinePlanDev,
    BlockLayout,
    TablePaymentHistory,
    ChatComet
  }
}
</script>
<style lang="scss">
.tabs-container{
  &.full-height.is-active{
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
.block-layout{
  border-radius: 16px;
  background-color: #fff;
  padding: 24px;
}
.chart-wrapper{
  padding: 0 80px;
}
</style>
