<template>
  <div>
    <header class="header bg-white br-bottom">
      <div class="header-logo h2">Workspace</div>
      <div class="header-rg"></div>
    </header>
    <div class="row row-32">
      <div class="col-lg-3">
        <div class="h3 color-dark-2 lb-mb-15 lb-tp-10">Clients</div>
        <div class="users-list-layout height-js no-min">
          <div class="users-list_scroll">
            <div class="users-list-header lb-mb-15">
              <div class="row row-8 align-items-center nowrap">
                <div class="col-w-100">
                  <div class="input-wrapper-icon clean">
                    <span class="icon"><img src="@/assets/img/icons/icon-search-grey.svg" alt="icon-search"></span>
                    <button
                      class="icon-clean"
                      v-if="search_users.length"
                      @click="search_users = ''"
                    >
                      <img src="@/assets/img/icons/icon-clear.svg" alt="icon-search">
                    </button>
                    <input class="input sm" placeholder="Search" type="text" v-model="search_users">
                  </div>
                </div>
                <div class="col-auto">
                  <button class="btn btn-ghost btn-icon">
                    <img src="@/assets/img/icons/icon-plus.svg" alt="icon">
                  </button>
                </div>
              </div>
            </div>
            <!--
            <div v-if="loader_users_friends">
              <div v-if="friends.length > 0">
                <div v-for="(item, i) in friends" :key="i">
                  <div
                    class="users-list-item lb-mb-8"
                    @click="actionHandler(item)"
                  >
                    <div class="row row-12 nowrap align-items-center">
                      <div class="col-auto">
                        <div class="image">
                          <img v-if="item.photo" :src="item.photo" alt="image-user">
                          <img v-else src="@/assets/img/default-user-avatar.png" alt="image-user">
                        </div>
                      </div>
                      <div class="col-w-100">
                        <div class="name">{{item.name}}</div>
                        <div class="message text-sm">Text</div>
                      </div>
                    </div>
                  </div>
                  <hr v-if="friends.length - 1 !== i" class="hr lb-mb-8">
                </div>
              </div>
              <div v-else class="text-center">none</div>
            </div>
            -->
            <template v-if="user_chat_login_is">
              <div v-if="is_done_users && user_chat_login_is">
                <template v-if="user_chat_login_is">
                  <nav-bar-friends
                    :users="friends"
                    :type="type"
                    @action="actionHandler"
                  ></nav-bar-friends>
                </template>
              </div>
              <div v-else class="loader-wrapper">
                <div class="loader-circle">
                  <img src="@/assets/img/loader-icon.png" alt="loader">
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="col-lg-9">
        <template v-if="active_user_id !== null && item !== null">
          <users-content
            v-if="user_chat_login_is"
            :item="item"
            :type="type"
            @action="actionHandler"
          >
          </users-content>
        </template>
        <template v-else>
          <div class="lb-tp-15">Select a user</div>
        </template>
      </div>
      <!-- :class="{'blur-content': users.length === 0}" -->
    </div>
  </div>
</template>
<script>
import UsersContent from '@/components/UsersContent'
import NavBarFriends from '@/components/ChatComet/NavBarFriends/index'
import { CometChat } from '@cometchat-pro/chat'
import { cometChatScreens } from '../mixins/'

export default {
  mixins: [cometChatScreens],
  data () {
    return {
      search_users: '',
      users_chat: [],
      active_user_chat: '',
      loader_users_chat: false,

      friends: [],
      loader_users_friends: false,

      item: null,
      type: 'user'
    }
  },
  computed: {
    is_done_users () {
      if (this.loader_users_chat && this.loader_users_friends) {
        return true
      } else {
        return false
      }
    },
    active_user_id () {
      return this.$store.getters.activeUser
    },
    user_chat_login_is: function () {
      return this.$store.getters.user_chat_login_is
    }
  },
  methods: {
    actionHandler ({ action, item, type }) {
      switch (action) {
        case 'item-click':
          this.itemClicked(item, type)
          break
        default:
          break
      }
    },
    get_users_chat: function () {
      const usersRequest = new CometChat.UsersRequestBuilder().setLimit(50).setSearchKeyword(this.search_users).friendsOnly(true).build()
      usersRequest.fetchNext().then(
        (users) => {
          this.friends = users
          this.loader_users_friends = true
        },
        (e) => {
          console.log(e)
        }
      )
      const chatRequest = new CometChat.ConversationsRequestBuilder().setLimit(50).setConversationType('user').build()
      chatRequest.fetchNext().then(
        (chats) => {
          this.users_chat = chats
          this.loader_users_chat = true
        },
        (e) => {
          console.log(e)
        }
      )
    }
  },
  mounted () {
    this.get_users_chat()
  },
  watch: {
    is_done_users: {
      handler (v) {
        if (v) {
          this.friends.map((e) => {
            const c = this.users_chat.find((a) => {
              return e.uid === a.conversationWith.uid
            })
            if (c) {
              const b = e
              b.lastMessage = c.lastMessage
              b.unreadMessageCount = c.unreadMessageCount
              return b
            }
          })
        }
      },
      deep: true
    },
    search_users: function () {
      this.get_users_chat()
    },
    friends: {
      handler (v) {
        if (v.length > 0) {
          this.$store.commit('updateUsersActiveUser', v[0])
          this.itemClicked(v[0], this.type)
        } else {
          this.$store.commit('updateUsersActiveUser', null)
        }
      },
      deep: true
    }
  },
  components: {
    UsersContent,
    NavBarFriends
  },
  destroyed () {
    this.$store.commit('cleanUsersActiveUser')
  }
}
</script>

<style lang="scss">
.blur-content{
  filter: blur(10px);
  pointer-events: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.users-list_scroll{
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2px 4px;
  margin: 0 -4px;
}
</style>
