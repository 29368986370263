<template>
  <div>
    <div v-if="!loadingPlan">
      <div class="lb-mb-20">
        <div class="row justify-content-between align-items-center">
          <div class="col-auto">
            <div class="h3 color-dark-2">Development plan</div>
          </div>
          <div class="col-auto">
            <div class="row row-8 align-items-center">
              <div class="col-auto">
                <div class="btn-group no-bg">
                  <button @click="calendarApi.prev()" class="btn btn-ghost btn-icon"><img src="@/assets/img/icons/icon-keyboard_arrow_left.svg" alt="icon"></button>
                  <button @click="calendarApi.next()" class="btn btn-ghost btn-icon"><img src="@/assets/img/icons/icon-keyboard_arrow_right.svg" alt="icon"></button>
                </div>
              </div>
              <div class="col-auto">
                <div class="h4 lb-mr-26" v-if="loadedLocal"><span class="months-min-width">{{calendarDateMonths}}</span> {{calendarDateYear}}</div>
              </div>
              <div class="col-auto">
                <button class="btn btn-outlined" @click="showModal = true">Activity</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="app-timeline-wrapper">
        <div v-if="plan">
          <full-calendar
            ref="fullCalendar"
            :options='calendarOptions'
          >
            <template v-slot:resourceAreaHeaderContent>
              <div class="row justify-content-between align-items-center nowrap">
                <div class="col-auto"><div class="color-grey text-md">Name task</div></div>
                <div class="col-auto">
                  <button
                    class="btn btn-ghost btn-icon"
                    @click="openNewTask"
                  >
                    <img src="@/assets/img/icons/icon-plus.svg" alt="icon">
                  </button>
                </div>
              </div>
            </template>
            <template v-slot:slotLabelContent="arg">
              <div>
                <div class="day-week">{{dateFormat(arg.date).dayWeek}}</div>
                <div class="day-month">{{dateFormat(arg.date).day}}</div>
              </div>
            </template>
            <template v-slot:eventContent='arg'>
              <div class="evetn-content">
                <div class="title">
                  <div>
                    {{arg.event.title}}
                  </div>
                  <div>
                    {{arg.event.extendedProps.subtask}}
                  </div>
                  <!--
                  {{arg.event.extendedProps.id}}
                  -->
                </div>
                <!--
                <div class="user">
                  <span class="image">
                    <img v-if="arg.event.extendedProps.urlImageUser" :src="arg.event.extendedProps.urlImageUser" alt="image">
                    <img v-else src="@/assets/img/default-user-avatar.png" alt="image">
                  </span>
                  <div>
                    <div class="name">{{arg.event.extendedProps.nameUser}}</div>
                    <div class="prof">{{arg.event.extendedProps.profUser}}</div>
                  </div>
                </div>
                -->
              </div>
            </template>
          </full-calendar>
        </div>
      </div>
    </div>
    <div v-else class="loader-wrapper">
      <div class="loader-circle">
        <img src="@/assets/img/loader-icon.png" alt="loader">
      </div>
    </div>
    <pop-up
      :show-modal="showModal"
      slide-animation="slideleft"
      view-modal-container="modal-container-two type-two"
      @closemodal="showModal = false"
    >
      <div class="h2 color-dark lb-mb-32">Activity</div>
      <div>
        <div class="timeline-item">
          <div class="image"><img src="@/assets/img/image-user-022.jpg" alt="image"></div>
          <div class="lb-mb-15">
            <div class="h4 color-dark lb-mb-4">John Doe</div>
            <div class="color-grey text-md">Today, 10:00 AM</div>
          </div>
          <div class="text-md color-dark">
            Missed the meeting with @Jennifer Stolen for unknown reasons.
          </div>
        </div>
        <div class="timeline-item">
          <div class="image"><img src="@/assets/img/image-user-022.jpg" alt="image"></div>
          <div class="lb-mb-15">
            <div class="h4 color-dark lb-mb-4">John Doe</div>
            <div class="color-grey text-md">Today, 10:00 AM</div>
          </div>
          <div class="text-md color-dark">
            Missed the meeting with @Jennifer Stolen for unknown reasons.
          </div>
        </div>
      </div>
    </pop-up>
    <pop-up
      :show-modal="showModalTwo"
      slide-animation="slideleft"
      view-modal-container="modal-container-two type-three"
      @closemodal="closeNewTask"
    >
      <div v-if="is_edit_task" class="h2 color-dark lb-mb-32">Edit task</div>
      <div v-else class="h2 color-dark lb-mb-32">Create a new task</div>
      <div v-if="task_edit">
        <div class="form-group md lb-mb-20">
          <div
            class="input-wrapper-material"
            :class="{'typed': task_edit.title}"
          >
            <div class="input-inner">
              <input
                class="input input-material"
                name="name"
                type="text"
                @focus="focusInput"
                @blur="blurInput($event); $v.task_edit.title.$touch()"
                @input="typedInput"
                @change="typedInput"
                v-model="task_edit.title"
                :class="{'error': $v.task_edit.title.$error}"
              >
              <label>Task title</label>
            </div>
            <div v-if="$v.task_edit.title.$error" class="input-error">Error</div>
          </div>
          <div
            class="input-wrapper-material"
            :class="{'typed': task_edit.subtask}"
          >
            <div class="input-inner">
              <input
                class="input input-material"
                name="name"
                type="text"
                @focus="focusInput"
                @blur="blurInput($event); $v.task_edit.subtask.$touch()"
                @input="typedInput"
                @change="typedInput"
                v-model="task_edit.subtask"
                :class="{'error': $v.task_edit.subtask.$error}"
              >
              <label>Subtask name</label>
            </div>
            <div v-if="$v.task_edit.subtask.$error" class="input-error">Error</div>
          </div>
        </div>
        <div class="gr-wrapper lb-mb-15">
          <div class="gr-label text-md color-dark-2">Start and deadline</div>
          <div class="gr-content">
            <div class="v-calendar_drop">
              <span class="v-calendar_drop-overlay" @click="dropVcClose"></span>
              <button class="btn btn-outlined v-calendar_drop-btn" @click="dropVcOpen">
                <span v-if="task_edit.date.start">{{dateDay(task_edit.date.start).day}} {{dateDay(task_edit.date.start).nameMonth}}, {{dateDay(task_edit.date.start).year}} - {{dateDay(task_edit.date.end).day}} {{dateDay(task_edit.date.end).nameMonth}}, {{dateDay(task_edit.date.end).year}}</span>
                <span v-else>Start date – End date</span>
              </button>
              <vc-date-picker
                class="v-calendar_own"
                v-model="task_edit.date"
                is-range
                :locale="{ id: 'en', masks: { weekdays: 'WW' } }"
              >
              </vc-date-picker>
            </div>
          </div>
        </div>
        <!--
        <div class="gr-wrapper lb-mb-15">
          <div class="gr-label text-md color-dark-2">Assign to mentor</div>
          <div class="gr-content">
            <div class="dropdown dropdown-user">
              <span class="dropdown-overlay" @click="dropClose"></span>
              <button class="dropdown-user-btn" @click="dropOpen">
              <span v-if="task_edit.user" class="dropdown-user-list">
                 <span class="image-wr">
                    <span class="image"><img src="@/assets/img/image-user-022.jpg" alt=""></span>
                    <span v-if="task_edit.user.status_online" class="st-online"></span>
                 </span>
                 <span class="name">{{task_edit.user.name}}</span>
              </span>
                <span v-else class="dropdown-user-unass">
                <span class="icon">
                  <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 3.933a1.4 1.4 0 110 2.8 1.4 1.4 0 010-2.8zm0 6c1.98 0 4.067.974 4.067 1.4v.734H3.934v-.734c0-.426 2.086-1.4 4.066-1.4zm0-7.266A2.666 2.666 0 108 8a2.666 2.666 0 100-5.333zm0 6c-1.78 0-5.333.893-5.333 2.666v2h10.667v-2C13.334 9.56 9.78 8.667 8 8.667z" class="fill"/></svg>
                </span>
                <span class="label">Unassigned</span>
              </span>
              </button>
              <div class="dropdown-inner">
                <div class="input-wrapper-simple lb-mb-8">
                  <input
                    placeholder="Search"
                    type="text"
                    class="input md"
                    v-model="searchUsers"
                  >
                </div>
                <ul class="list">
                  <li @click="task_edit.mentor = ''">Unassigned</li>
                  <li
                    v-for="(item, i) in resultUsers"
                    :key="i + 's'"
                    @click="task_edit.mentor = item"
                  >
                  <span class="dropdown-user-list">
                    <span class="image-wr">
                      <span class="image"><img src="@/assets/img/image-user-022.jpg" alt=""></span>
                      <span v-if="item.status_online" class="st-online"></span>
                    </span>
                    <span class="name">{{item.name}}</span>
                  </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        -->
        <div class="gr-wrapper lb-mb-40">
          <div class="gr-label text-md color-dark-2">Color</div>
          <div class="gr-content">
            <div class="choose-option-wr">
              <label
                class="choose-option"
                v-for="(item, i) in colors"
                :key="i + 'z'"
                :style="{'background-color': item}"
              >
                <input
                  type="radio"
                  :value="item"
                  v-model="task_edit.color"
                >
                <span></span>
              </label>
            </div>
          </div>
        </div>
        <div class="btn-wrapper">
          <button
            v-if="is_edit_task"
            class="btn btn-primary btn-loader"
            :class="{'disable': $v.$invalid, 'loader': loadingSave}"
            @click="saveTask"
          >
            Save
          </button>
          <button
            v-else
            class="btn btn-primary btn-loader"
            :class="{'disable': $v.$invalid, 'loader': loadingSend}"
            @click="sendNewTask"
          >
            Create task
          </button>
          <button class="btn btn-secondary" @click="closeNewTask">Cancel</button>
        </div>
      </div>
    </pop-up>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import { required } from 'vuelidate/lib/validators'
import PopUp from '@/components/PopUp'
export default {
  data () {
    return {
      colors: ['#DDE4FF', '#CCEACE', '#F4D3E4', '#F3B4B4', '#A6EDE0', '#FAE8BA'],
      users: [
        {
          id: 123,
          name: 'Cody Fisher',
          photo: '',
          status_online: true
        },
        {
          id: 143,
          name: 'John Doe',
          photo: '',
          status_online: false
        },
        {
          id: 14,
          name: 'Ronald Mckeever',
          photo: '',
          status_online: true
        }
      ],
      searchUsers: '',
      showModal: false,
      showModalTwo: false,
      loadedLocal: false,
      task_edit: '',
      task_model: {
        title: '',
        subtask: '',
        date: {
          start: null,
          end: null
        },
        color: ''
      },
      is_edit_task: false,
      currentEvents: [],
      loadingPlan: true,
      loadingSend: false,
      loadingSave: false
    }
  },
  computed: {
    plan () {
      return this.$store.getters.plan
    },
    calendarOptions () {
      const r = []
      this.plan.forEach((e) => {
        r.push({
          id: e.resourceId,
          title: e.title,
          eventColor: e.color
        })
      })
      return {
        schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
        plugins: [
          resourceTimelinePlugin
        ],
        headerToolbar: false,
        initialView: 'resourceTimelineMonth',
        weekNumberFormat: {
          week: 'short'
        },
        resourcesInitiallyExpanded: false,
        slotMinWidth: 45,
        resourceAreaWidth: '24%',
        editable: false,
        handleWindowResize: false,
        nowIndicator: true,
        resources: r,
        events: this.plan,
        eventsSet: this.handleEvents,
        eventClick: this.openEditTask
      }
    },
    resultUsers () {
      return this.users.filter(v => {
        if (this.searchUsers === '') {
          return true
        } else {
          return v.name.toLowerCase().includes(this.searchUsers.toLowerCase())
        }
      })
    },
    calendarApi () {
      return this.$refs.fullCalendar.getApi()
    },
    calendarDateMonths () {
      return this.getNameMonth(this.calendarApi.getDate().getMonth())
    },
    calendarDateYear () {
      return this.calendarApi.getDate().getFullYear()
    },
    active_user_id () {
      return this.$store.getters.activeUser
    }
  },
  methods: {
    console_log (e) {
      console.log(e)
    },
    focusInput: function (e) {
      e.target.closest('.input-wrapper-material').classList.add('focus')
    },
    blurInput: function (e) {
      e.target.closest('.input-wrapper-material').classList.remove('focus')
    },
    typedInput: function (e) {
      if (e.target.value) {
        e.target.closest('.input-wrapper-material').classList.add('typed')
      } else {
        e.target.closest('.input-wrapper-material').classList.remove('typed')
      }
    },
    dropOpen: function (e) {
      e.target.closest('.dropdown').classList.add('is-active')
    },
    dropClose: function (e) {
      e.target.closest('.dropdown').classList.remove('is-active')
    },
    dropVcOpen: function (e) {
      e.target.closest('.v-calendar_drop').classList.add('is-active')
    },
    dropVcClose: function (e) {
      e.target.closest('.v-calendar_drop').classList.remove('is-active')
    },
    scrollHgCalendar: function () {
      const $e = document.querySelector('.app-timeline-wrapper')
      if ($e) {
        const t = $e.getBoundingClientRect().top + pageYOffset
        const wh = window.innerHeight
        this.calendarApi.setOption('height', wh - (t + 50))
      }
    },
    handleEvents (events) {
      this.currentEvents = events
      this.loadedLocal = true
      this.scrollHgCalendar()
    },
    dateFormat (e) {
      const t = new Date(e)
      const date = t.getFullYear() + '-' + (t.getMonth()) + '-' + t.getDate()
      return {
        date: date,
        day: t.getDate(),
        dayWeek: this.getNameDayWeek(t.getDay()),
        month: t.getMonth(),
        year: t.getFullYear(),
        nameMonth: this.getNameMonth(t.getMonth())
      }
    },
    dateDay (e) {
      const t = new Date(e)
      const date = t.getFullYear() + '-' + (t.getMonth()) + '-' + t.getDate()
      return {
        date: date,
        day: t.getDate(),
        dayWeek: this.getNameDayWeek(t.getDay()),
        month: t.getMonth(),
        year: t.getFullYear(),
        nameMonth: this.getNameMonth(t.getMonth())
      }
    },
    getNameMonth (e) {
      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      return months[e]
    },
    getNameDayWeek (e) {
      const months = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
      return months[e]
    },
    openNewTask () {
      this.is_edit_task = false
      this.task_edit = JSON.parse(JSON.stringify(this.task_model))
      this.showModalTwo = true
    },
    closeNewTask () {
      this.showModalTwo = false
      this.task_edit = ''
    },
    sendNewTask () {
      const c = {
        resourceId: Math.floor(Math.random() * 1111),
        title: this.task_edit.title,
        subtask: this.task_edit.subtask,
        start: this.task_edit.date.start,
        end: this.task_edit.date.end,
        color: this.task_edit.color ? this.task_edit.color : '#DDE4FF',
        client_id: this.active_user_id
      }
      this.loadingSend = true
      this.$store.dispatch('sendPlan', c)
        .finally(() => {
          this.loadingSend = false
          this.showModalTwo = false
          this.task_edit = ''
          this.fetchPlan()
        })
    },
    openEditTask (e) {
      this.is_edit_task = true
      const c = this.$store.getters.one_plan(+e.event._def.publicId)
      c.date = {
        start: c.start,
        end: c.end
      }
      this.task_edit = JSON.parse(JSON.stringify(c))
      this.showModalTwo = true
    },
    saveTask () {
      this.loadingSave = true
      const c = {
        resourceId: this.task_edit.resourceId,
        title: this.task_edit.title,
        subtask: this.task_edit.subtask,
        start: this.task_edit.date.start,
        end: this.task_edit.date.end,
        color: this.task_edit.color ? this.task_edit.color : '#DDE4FF',
        client_id: this.active_user_id,
        id: this.task_edit.id
      }
      this.$store.dispatch('savePlan', c)
        .finally(() => {
          this.loadingSave = false
          this.showModalTwo = false
          this.task_edit = ''
          this.fetchPlan()
        })
    },
    fetchPlan () {
      this.$store.dispatch('fetchPlan')
        .finally(() => {
          this.loadingPlan = false
        })
    }
  },
  mounted () {
    window.addEventListener('resize', this.scrollHgCalendar)
    this.fetchPlan()
  },
  updated () {
    window.addEventListener('resize', this.scrollHgCalendar)
  },
  watch: {
    active_user_id: function () {
      this.loadingPlan = true
      this.$store.dispatch('fetchPlan')
        .finally(() => {
          this.loadingPlan = false
        })
    }
  },
  validations: {
    task_edit: {
      title: {
        required
      },
      subtask: {
        required
      },
      date: {
        start: {
          required
        },
        end: {
          required
        }
      }
    }
  },
  components: {
    FullCalendar,
    PopUp
  }
}
</script>
<style lang="scss">
@import "../assets/scss/_v-calendar.scss";
.choose-option{
  &-wr{
    display: flex;
  }
  width: 20px;
  height: 20px;
  margin-right: 4px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  transition: all .34s ease-out;
  input{
    display: none;
  }
  span{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 0px 0px 0px 1.5px #5E4BF1;
    border-radius: 50%;
    display: none;
  }
  input:checked + span{
    display: inline-block;
  }
}
.choose-option:hover{
  opacity: 0.8;
}
.dropdown-user{
  &-list{
    display: flex;
    align-items: center;
    .image-wr{
      display: inline-block;
      position: relative;
      flex: 0 0 22px;
      margin-right: 6px;
    }
    .image{
      display: block;
      height: 22px;
      width: 22px;
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      img{
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .name{
      white-space: nowrap;
      font-size: 14px;
    }
    .st-online{
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      border: 2px solid #fff;
      background-color: #02BB9F;
      position: absolute;
      bottom: -1px;
      right: -1px;
    }
  }
  .list{
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: -6px;
    padding-right: 6px;
  }
  &-btn{
    border-radius: 6px;
    padding: 4px 8px;
    background: transparent;
    cursor: pointer;
    transition: all .34s ease-out;
  }
  &-unass{
    display: inline-flex;
    align-items: center;
    font-size: 13px;
    color: #767676;
    .icon{
      display: inline-block;
      width: 16px;
      line-height: 0;
      margin-right: 3px;
      img{
        max-width: 18px;
      }
    }
    .fill{
      fill: #767676;
      transition: all .34s ease-out;
    }
  }
}
.dropdown-user.is-active{
  .dropdown-user-btn{
    background: rgba(94, 75, 241, 0.12);
    .dropdown-user-unass{
      color: #5E4BF1;
    }
    .fill{
      fill: #5E4BF1;
    }
  }
}
.dropdown-user-btn:hover{
  background: rgba(94, 75, 241, 0.12);
  .dropdown-user-unass{
    color: #5E4BF1;
  }
  .fill{
    fill: #5E4BF1;
  }
}
.gr{
  &-wrapper{
    display: flex;
    align-items: center;
  }
  &-label{
    width: 170px;
    margin-right: 10px;
  }
}
.app-timeline-wrapper{
  .fc .fc-timeline-now-indicator-arrow{
    display: none;
  }
  .fc .fc-timeline-now-indicator-line{
    border-width: 0 0 0 2px;
    transform: translateX(23px);
    border-color: #FA665B;
    &:after{
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      background-color: #FA665B;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-5px);
    }
  }
  .fc-timeline-event{
    border-radius: 8px;
    height: 44px;
    position: relative;
    bottom: -5px!important;
    padding: 4px 16px;
  }
  .fc-event-main{
    > span{
      display: block;
      width: 100%;
      line-height: 1.2em;
    }
  }
  .evetn-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .title{
      color: #161616;
      font-size: 14px;
    }
    .user{
      display: flex;
      align-items: center;
      line-height: 1em;
    }
    .image{
      display: inline-block;
      width: 30px;
      height: 30px;
      background-color: #fff;
      border-radius: 50%;
      margin-right: 8px;
      overflow: hidden;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .name{
      color: #161616;
      font-size: 12px;
      margin-bottom: 1px;
    }
    .prof{
      color: #767676;
      font-size: 12px;
    }
  }
  .day-week{
    color: #767676;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 2px;
    font-weight: 400;
  }
  .day-month{
    width: 22px;
    height: 22px;
    margin: 0 auto;
    border-radius: 8px;
    text-align: center;
    line-height: 23px;
    font-size: 12px;
    font-weight: 400;
  }
  .fc-timeline-slot-label.fc-day-today{
    .day-month{
      background: #FA665B;
      color: #FFFFFF;
    }
  }
  .fc-theme-standard .fc-scrollgrid {
    border: none;
  }
  .fc-scrollgrid-section-header,
  .fc-scrollgrid-section-body{
    > td:last-of-type{
      border-right: 0;
    }
  }
  .fc-scrollgrid-section-body{
    > td:first-of-type .fc-scroller.fc-scroller-liquid-absolute{
      overflow: hidden!important;
    }
  }
  .fc-resource-timeline-divider.fc-cell-shaded{
    opacity: 0;
    visibility: hidden;
    width: 0;
  }
  .fc-datagrid-cell-frame{
    display: flex;
    align-items: center;
  }
  .fc-scroller-harness{
    .fc-datagrid-cell-cushion.fc-scrollgrid-sync-inner{
      width: 100%;
    }
  }
}

</style>
